import { User } from '@auth0/auth0-react';
import AddIcon from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonAddAlt1SharpIcon from '@mui/icons-material/PersonAddAlt1Sharp';
import { Fab } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Collection, CollectionSet, StudentTechnique, TechniqueStatus } from 'common';
import React from 'react';
import { Options, debounce } from 'ts-debounce';
import syllabusTrackerApi from '../../../services/syllabusTrackerApi';
import Pageloader from '../../Base/PageLoader';
import { renderEditCollectionSetDialog } from '../../Dialogs/EditCollectionSetDialog';
import { renderNewCollectionSetDialog } from '../../Dialogs/NewCollectionSetDialog';
import { renderSelectStudentDialog } from '../../Dialogs/SelectStudentDialog';
import CollectionList from './CollectionList';
import DragDropCollectionsList from './DragDropCollections';


const Accordion = styled(MuiAccordion)({
    backgroundColor: `#3c3836`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    },
    '&:not(:last-child)': {
        borderBottom: '1px solid #7c6f64'
    }
});

const SubAccordion = styled(MuiAccordion)({
    backgroundColor: `inherit`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    }
});

const ListItem = styled(MuiListItem)({
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px"
})

const BaseListItemText: React.FC<ListItemTextProps> = (props) => {
    return (
        <MuiListItemText
            {...props}
            secondaryTypographyProps={{ component: 'div' }}
            primaryTypographyProps={{ component: 'div' }}
        />
    );
}

const ListItemText = styled(BaseListItemText)<ListItemTextProps>(({ theme }) => {
    let primaryVariant = 'h6';
    let secondaryVariant = 'body1';

    return {
        '& .MuiTypography-root': {
            variant: primaryVariant
        },
        '& .MuiTypography-colorTextSecondary': {
            variant: secondaryVariant
        }
    };
});

const SubCard = styled(MuiCard)({
    backgroundColor: 'inherit'
})

function CollectionSetList(): JSX.Element {
    const { data: collectionSets, isLoading, isSuccess } = syllabusTrackerApi.useGetCollectionSetsQuery();
    const [updateCollectionSet] = syllabusTrackerApi.useEditCollectionSetMutation();
    const [postStudentTechniques] = syllabusTrackerApi.usePostStudentTechniquesMutation();
    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)
    const handleDialogClose = () => { setDialogComponent(null); setShowNewCollectionFab(true); }
    const [showNewCollectionSetFab, setShowNewCollectionFab] = React.useState(true)

    const [showDragDropList, setShowDragDropList] = React.useState('')

    const openDragDrop = (collectionSet: CollectionSet) => { setShowDragDropList(collectionSet.collectionSetId) }
    const closeDragDrop = () => { setShowDragDropList('') }
    const handleDragDropSubmit = (collectionSet: CollectionSet, collections: Collection[]) => {

        console.log({
            ...collectionSet,
            collections: collections
        })
        updateCollectionSet({
            ...collectionSet,
            collections: collections
        })
        closeDragDrop();
    };

    const handleSelectStudent = (student: User, collectionSet: CollectionSet) => {
        const studentTechniques: Partial<StudentTechnique>[] = collectionSet.collections?.flatMap(c =>
            c.collectionTechniques?.map(ct => ({
                technique: ct.technique,
                userId: student.user_id || student.sub,
                status: TechniqueStatus.NotYetStarted,
            })) || []
        ) || [];

        postStudentTechniques(studentTechniques);
    }

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedSubmit = debounce(handleDragDropSubmit, 5000, debounceOptions)

    return (
        <>
            <Fab
                color="primary"
                aria-label="add"
                style={{ position: 'fixed', bottom: '16px', right: '16px' }}
                onClick={() => { setDialogComponent(renderNewCollectionSetDialog(handleDialogClose)); setShowNewCollectionFab(false); console.log('test') }}
                hidden={!showNewCollectionSetFab}
            >
                <AddIcon />
            </Fab >

            {dialogComponent}
            {isLoading ? <Pageloader />
                : isSuccess && collectionSets.map(collectionSet => {
                    return (
                        <Accordion disableGutters key={collectionSet.collectionSetId} >
                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content">
                                <Box display="flex" flexDirection="column" flexGrow={1}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="97%">
                                        <Typography variant="h6">{collectionSet.title}</Typography>
                                        <Box display="flex" flexDirection="row" justifyItems="center">
                                            <PersonAddAlt1SharpIcon sx={{ marginRight: "15px" }} onClick={(e) => {
                                                e.stopPropagation();
                                                setDialogComponent(renderSelectStudentDialog(handleDialogClose, handleSelectStudent, undefined, collectionSet));
                                            }} />
                                            <Edit onClick={(event) => {
                                                event.stopPropagation();
                                                setDialogComponent(renderEditCollectionSetDialog(collectionSet, handleDialogClose))
                                                setShowNewCollectionFab(false)
                                            }} />
                                        </Box>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <SubCard elevation={0}>
                                    <SubAccordion elevation={0} disableGutters square defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: "0px", margin: "0px" }}>
                                            <ListItem key={`${collectionSet.collectionSetId}-collectionSet-collections`}>
                                                <Box display="flex" alignItems="center" justifyContent="flex-start" width="97%">
                                                    <ListItemText primary="Collections" />
                                                    {!showDragDropList &&
                                                        <Edit onClick={(event) => { event.stopPropagation(); openDragDrop(collectionSet); }} />
                                                    }
                                                </Box>
                                            </ListItem>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: "0px" }}>
                                            {isLoading ? <Pageloader /> :
                                                showDragDropList === collectionSet.collectionSetId ?
                                                    <Box>
                                                        <DragDropCollectionsList
                                                            collectionSet={collectionSet}
                                                            onDragDropSave={debouncedSubmit}
                                                            onDragDropCancel={closeDragDrop}
                                                        />
                                                    </Box>
                                                    : <CollectionList
                                                        collections={collectionSet.collections ?? []}
                                                        elevation={0}
                                                        subElevation={1}
                                                        smallText
                                                    />
                                            }
                                        </AccordionDetails>
                                    </SubAccordion>

                                    <ListItem key={`${collectionSet.collectionSetId}-description`}>
                                        <ListItemText primary="Description" secondary={collectionSet?.description} />
                                    </ListItem>

                                </SubCard>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
        </>
    )
}

export default CollectionSetList
