import AddIcon from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Checkbox, Fab } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Collection, CollectionTechnique, Technique } from 'common';
import React from 'react';
import { Options, debounce } from 'ts-debounce';
import syllabusTrackerApi from '../../../services/syllabusTrackerApi';
import Pageloader from '../../Base/PageLoader';
import { renderEditCollectionDialog } from '../../Dialogs/EditCollectionDialog';
import { renderNewCollectionDialog } from '../../Dialogs/NewCollectionDialog';
import DragDropTechniquesList from './DragDropTechniques';
import TechniqueList from './TechniqueList';


const Accordion = styled(MuiAccordion)({
    backgroundColor: `#3c3836`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    },
    '&:not(:last-child)': {
        borderBottom: '1px solid #7c6f64'
    }
});

const SubAccordion = styled(MuiAccordion)({
    backgroundColor: `inherit`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    }
});

const ListItem = styled(MuiListItem)({
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px"
})

const BaseListItemText: React.FC<ListItemTextProps> = (props) => {
    return (
        <MuiListItemText
            {...props}
            secondaryTypographyProps={{ component: 'div' }}
            primaryTypographyProps={{ component: 'div' }}
        />
    );
}

const ListItemText = styled(BaseListItemText)<ListItemTextProps>(({ theme }) => {
    let primaryVariant = 'h6';
    let secondaryVariant = 'body1';

    return {
        '& .MuiTypography-root': {
            variant: primaryVariant
        },
        '& .MuiTypography-colorTextSecondary': {
            variant: secondaryVariant
        }
    };
});

const SubCard = styled(MuiCard)({
    backgroundColor: 'inherit'
})

interface CollectionsListProps {
    collections: Collection[];
    checkedArray?: boolean[];
    checkbox?: boolean;
    onCheck?: (collection: Collection) => void;
    elevation: number;
    subElevation: number;
    editable: boolean;
    smallText: boolean;
}

CollectionList.defaultProps = {
    elevation: 3,
    checkbox: false,
    editable: false,
    smallText: false,
    subElevation: 0
}

function CollectionList({ editable, elevation, collections, checkbox, checkedArray, onCheck, smallText, subElevation }: CollectionsListProps): JSX.Element {
    const [updateCollection, { isLoading }] = syllabusTrackerApi.useEditCollectionMutation();
    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)
    const handleDialogClose = () => { setDialogComponent(null); setShowNewCollectionFab(true); }
    const [showNewCollectionFab, setShowNewCollectionFab] = React.useState(true)

    const [showDragDropList, setShowDragDropList] = React.useState('')

    const openDragDrop = (collection: Collection) => { setShowDragDropList(collection.collectionId) }
    const closeDragDrop = () => { setShowDragDropList('') }
    const handleDragDropSubmit = (collection: Collection, dragDropTechniques: { index: number, technique: Technique, id?: string }[]) => {
        const collectionTechniques: Partial<CollectionTechnique>[] = dragDropTechniques.map(indexTechniquePair => {
            return {
                collectionTechniqueId: indexTechniquePair.id,
                collectionId: collection.collectionId,
                technique: indexTechniquePair.technique,
                order: indexTechniquePair.index + 1
            }
        })
        updateCollection({
            ...collection,
            collectionTechniques: collectionTechniques
        })
        closeDragDrop();
    };

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedSubmit = debounce(handleDragDropSubmit, 5000, debounceOptions)

    return (
        <>
            {!smallText &&
                <Fab
                    color="primary"
                    aria-label="add"
                    style={{ position: 'fixed', bottom: '16px', right: '16px' }}
                    onClick={() => { setDialogComponent(renderNewCollectionDialog(handleDialogClose)); setShowNewCollectionFab(false); }}
                    hidden={!showNewCollectionFab}
                >
                    <AddIcon />
                </Fab >
            }

            {dialogComponent}
            {collections.map((collection, index) => {
                let unsortedTechniques: CollectionTechnique[] = []
                if (collection.collectionTechniques) {
                    unsortedTechniques = [...collection.collectionTechniques];
                }
                let collectionTechniques: Technique[] = []
                unsortedTechniques.sort((a, b) => a.order - b.order)
                unsortedTechniques.forEach(collectionTechnique => {
                    collectionTechniques.push(collectionTechnique.technique)
                });
                return (
                    <Accordion disableGutters elevation={elevation} key={collection.collectionId} >
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content">
                            <Box display="flex" flexDirection="column" flexGrow={1}>
                                {!checkbox &&
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="97%">
                                        <Typography variant={smallText ? "body1" : "h6"}>{collection.title}</Typography>
                                        {editable &&
                                            <Edit onClick={(event) => {
                                                event.stopPropagation();
                                                setDialogComponent(renderEditCollectionDialog(collection, handleDialogClose))
                                                setShowNewCollectionFab(false)
                                            }} />
                                        }
                                    </Box>
                                }

                                <Box display="flex" flexDirection="row" width="100%">
                                    {checkbox && (
                                        <Box display="flex" alignItems="center" marginLeft="0px">
                                            <Checkbox
                                                size='small'
                                                checked={checkedArray?.[index] ?? false}
                                                onChange={() => onCheck?.(collection)}
                                                onClick={e => e.stopPropagation()}
                                            />
                                            <Typography variant="body1">{collection.title}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SubCard elevation={0}>
                                <SubAccordion elevation={0} disableGutters square defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: "0px", margin: "0px" }}>
                                        <ListItem key={`${collection.collectionId}-collection-techniques`}>
                                            <Box display="flex" alignItems="center" justifyContent="flex-start" width="97%">
                                                <ListItemText primary="Collection Techniques" />
                                                {editable && !showDragDropList &&
                                                    <Edit onClick={(event) => { event.stopPropagation(); openDragDrop(collection); }} />
                                                }
                                            </Box>
                                        </ListItem>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px" }}>
                                        {isLoading ? <Pageloader /> :
                                            showDragDropList === collection.collectionId ?
                                                <Box>
                                                    <DragDropTechniquesList
                                                        collection={collection}
                                                        onDragDropSave={debouncedSubmit}
                                                        onDragDropCancel={closeDragDrop}
                                                    />
                                                </Box>
                                                : <TechniqueList
                                                    filteredTechniques={collectionTechniques}
                                                    elevation={subElevation}
                                                    ordered={true}
                                                    editable={editable}
                                                />
                                        }
                                    </AccordionDetails>
                                </SubAccordion>

                                <ListItem key={`${collection.collectionId}-description`}>
                                    <ListItemText primary="Description" secondary={collection?.description} />
                                </ListItem>

                                {collection.globalNotes &&
                                    <ListItem key={`${collection.collectionId}-global-notes`}>
                                        <ListItemText primary="Global Notes" secondary={collection.globalNotes} />
                                    </ListItem>
                                }

                                {collection.position &&
                                    <SubAccordion elevation={0} disableGutters square>
                                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: "0px", margin: "0px" }}>
                                            <ListItem key={`${collection.collectionId}-position`}>
                                                <ListItemText primary="Position" secondary={collection.position?.title} />
                                            </ListItem>
                                        </AccordionSummary>

                                        <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>
                                            <ListItem key={`${collection.collectionId}-position-description`}>
                                                <ListItemText secondary={collection.position?.description} />
                                            </ListItem>
                                        </AccordionDetails>
                                    </SubAccordion>
                                }

                                {collection.hierarchy &&
                                    <ListItem key={`${collection.collectionId}-hierarchy`}>
                                        <ListItemText primary=" Hierarchy" secondary={collection.hierarchy} />
                                    </ListItem>
                                }

                                {collection.type &&
                                    <SubAccordion elevation={0} disableGutters square>
                                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: "0px", margin: "0px" }}>
                                            <ListItem key={`${collection.collectionId}-type`}>
                                                <ListItemText primary="Type" secondary={collection.type?.title} />
                                            </ListItem>
                                        </AccordionSummary>

                                        <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>
                                            <ListItem key={`${collection.collectionId}-type-description`}>
                                                <ListItemText secondary={collection.type?.description} />
                                            </ListItem>
                                        </AccordionDetails>
                                    </SubAccordion>
                                }

                                {collection.openGuard &&
                                    <SubAccordion elevation={0} disableGutters square>
                                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: "0px", margin: "0px" }}>
                                            <ListItem key={`${collection.collectionId}-open-guard`}>
                                                <ListItemText primary="Open Guard" secondary={collection.openGuard?.title} />
                                            </ListItem>
                                        </AccordionSummary>

                                        <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>
                                            <ListItem key={`${collection.collectionId}-open-guard-description`}>
                                                <ListItemText secondary={collection.openGuard?.description} />
                                            </ListItem>
                                        </AccordionDetails>
                                    </SubAccordion>
                                }

                                {collection.gi &&
                                    <ListItem key={`${collection.collectionId}-gi`}>
                                        <ListItemText primary="Gi or No Gi" secondary={collection.gi} />
                                    </ListItem>
                                }

                            </SubCard>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </>
    )
}

export default CollectionList
