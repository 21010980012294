import { AccountCircleSharp } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, DialogActions, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthButtons from '../Authentication/AuthButtons';


interface NavBarProps {
    text: string;
}

const NavBar = (props: NavBarProps) => {
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" elevation={2}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        aria-label="menu"
                        onClick={handleClickOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box display="flex" alignItems="center" flexGrow={1}>
                        <img
                            src="https://dominance.com.au/wp-content/uploads/2019/01/cropped-favicon-32x32.png"
                            alt="dominance-logo"
                            height="32px"
                            width="32px"
                            onClick={() => navigate('/')}
                        />
                        <Typography style={{ marginLeft: "8px" }} variant="h6">
                            {props.text}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer open={open} onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                <Box sx={{ width: 250 }} role="presentation">
                    <List>
                        <ListItem alignItems="flex-start" disablePadding key="profile">
                            <ListItemButton onClick={() => navigate('/profile')}>
                                <ListItemIcon>
                                    <AccountCircleSharp />
                                </ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <Divider />
                    <DialogActions >
                        <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                            <AuthButtons />
                        </Box>
                    </DialogActions>
                </Box>
            </Drawer>
        </React.Fragment >
    );
};

export default NavBar
