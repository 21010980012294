import MuiCard from "@mui/material/Card";
import CollectionSetList from "../../../components/Lists/Base Lists/CollectionSetList";
import { styled } from "@mui/material";


const Card = styled(MuiCard)({
    '&.MuiCard-root': {
        marginLeft: "10px",
        marginTop: "10px",
        marginRight: "10px",
        borderRadius: "2",
        boxShadow: "3"
    }
});


function CoachCollectionSets(): JSX.Element {
    return (
        <Card>
            <CollectionSetList />
        </Card>
    );
};

export default CoachCollectionSets
