import { Role } from 'common';
import React from 'react';
import BaseLayout from '../../components/Base/BaseLayout';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import CoachCollections from '../users/coach/Collections';
import StudentCollections from '../users/student/Collections';


const CollectionsPage: React.FC = () => {
    const { data: user, isSuccess } = syllabusTrackerApi.useGetUserQuery()

    return (
        <BaseLayout text="Collections">
            {isSuccess && (
                user.role === Role.Student ? <StudentCollections />
                    : user.role === Role.Coach ? <CoachCollections />
                        : user.role === Role.Admin && <p>Admin Collections page placeholder</p>
            )}

        </BaseLayout>
    );
}

export default CollectionsPage;
