import { User } from '@auth0/auth0-react';
import { styled } from '@mui/material';
import MuiCard from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CollectionSet } from 'common';
import React from 'react';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import Pageloader from '../Base/PageLoader';
import StudentList from '../Lists/Base Lists/StudentList';


const Card = styled(MuiCard)({
    backgroundColor: `#3c3836`,
    '&.MuiCard-root': {
        margin: "10px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

interface SelectStudentDialogProps {
    onClose: () => void;
    onCollectionSetSelect?: (student: User, collectionSet: CollectionSet) => void,
    onStudentSelect?: (student: User) => void,
    collectionSet?: CollectionSet
};

export const renderSelectStudentDialog = (
    onClose: () => void,
    onCollectionSetSelect?: (student: User, collectionSet: CollectionSet) => void,
    onStudentSelect?: (student: User) => void,
    collectionSet?: CollectionSet
) => {
    return <SelectStudentDialog onClose={onClose} onCollectionSetSelect={onCollectionSetSelect} onStudentSelect={onStudentSelect} collectionSet={collectionSet} />
}

const SelectStudentDialog = ({ onClose, onCollectionSetSelect, collectionSet, onStudentSelect }: SelectStudentDialogProps) => {
    const [isOpen, setIsOpen] = React.useState(true)

    const handleSelectStudent = (student: User) => {
        if (onCollectionSetSelect && collectionSet) {
            onCollectionSetSelect(student, collectionSet)
        } if (onStudentSelect) {
            onStudentSelect(student)
        }
        setIsOpen(false)
        onClose()
    }

    const { data: students, isLoading, isSuccess } = syllabusTrackerApi.useGetStudentsQuery()

    return (
        <Dialog open={isOpen} onClose={() => { onClose(); setIsOpen(false) }} scroll="paper" maxWidth="md" fullWidth>
            <DialogTitle sx={{ padding: "0px", marginBottom: "10px" }}>
                <Card>
                    Filter for students maybe
                </Card>
            </DialogTitle>

            <DialogContent dividers={true} sx={{ padding: "0px" }}>
                {isLoading ? <Pageloader /> :
                    isSuccess &&
                    <Card>
                        <StudentList students={students} onSelectStudent={handleSelectStudent} />
                    </Card>
                }
            </DialogContent>
        </Dialog>
    )
};
