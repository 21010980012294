import { Box } from '@mui/material';
import React from 'react';
import NavBar from './NavBar';

interface BaseLayoutProps {
    children: React.ReactNode;
    text: string;
}

const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
    return (
        <div>
            <NavBar
                text={props.text} />
            <Box sx={{ paddingTop: "70px" }}>{props.children}</Box>
        </div>
    );
}

export default BaseLayout;
