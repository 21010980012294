import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Collection, Technique } from 'common';
import MuiCard from '@mui/material/Card';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import DragHandleIcon from '@mui/icons-material/DragHandle'; // Suitable drag handle
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'
import MuiButton, { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button'
import { renderAddTechniqueToCollectionDialog } from '../../Dialogs/AddTechniqueToCollectionDialog';


const Card = styled(MuiCard)({
    backgroundColor: "inherit",
});

const Accordion = styled(MuiAccordion)({
    backgroundColor: `#3c3836`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    }
});

const CustomButton = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", margin: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));

const AccordionWrapper = styled('div')({
    borderBottom: '1px solid #7c6f64'
});

interface DragDropTechniquesListProps {
    collection: Collection;
    onDragDropSave: (collection: Collection, orderedTechniques: { index: number, technique: Technique, id?: string }[]) => void;
    onDragDropCancel: () => void;
}

function DragDropTechniquesList({ collection, onDragDropSave, onDragDropCancel }: DragDropTechniquesListProps): JSX.Element {
    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)
    const initialTechniques = collection.collectionTechniques
        ? collection.collectionTechniques.map((ct, index) => ({ index, technique: ct.technique, id: ct.collectionTechniqueId }))
        : [];
    const [orderedTechniques, setOrderedTechniques] = React.useState<{ index: number, technique: Technique, id?: string }[]>(initialTechniques);

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;
        const reorderedTechniques = Array.from(orderedTechniques);
        const [reorderedItem] = reorderedTechniques.splice(result.source.index, 1);
        reorderedTechniques.splice(result.destination.index, 0, reorderedItem);
        handleReorder(reorderedTechniques);
    };

    const handleReorder = (newDragDropOrder: { index: number, technique: Technique, id?: string }[]) => {
        const newOrder = newDragDropOrder.map((item, index) => {
            return { index, technique: item.technique, id: item.id }
        })
        setOrderedTechniques(newOrder)
    }

    const handleDelete = (deletedTechnique: { index: number, technique: Technique, id?: string }) => {
        setOrderedTechniques(orderedTechniques.filter(item => item.technique !== deletedTechnique.technique))
    };

    const handleDialogClose = () => {
        setDialogComponent(null)
    }

    const handleDialogSave = (selectedTechniques: { index: number, technique: Technique, id?: string }[]) => {
        const maxIndex = orderedTechniques.length - 1
        const addedTechniques = selectedTechniques.map((indexTechniquePair, index) => {
            return { index: maxIndex + index, technique: indexTechniquePair.technique, id:indexTechniquePair.id }
        })
        setOrderedTechniques([...orderedTechniques, ...addedTechniques])
    }

    const handleDialogOpen = () => {
        setDialogComponent(renderAddTechniqueToCollectionDialog(collection, handleDialogClose, handleDialogSave))
    }

    return (
        <React.Fragment>
            {orderedTechniques.length === 0 ?
                <Card elevation={0}>
                    <Accordion elevation={0} disableGutters square sx={{ borderBottom: '1px solid #7c6f64' }}>
                        <AccordionSummary>
                            <Typography variant="body1">
                                No techniques in collection
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Accordion disableGutters square elevation={0}>
                        <AccordionSummary >
                            <Box display="flex" width="100%" alignItems="center" justifyContent='center'>
                                <Button
                                    onClick={handleDialogOpen}
                                    size="large"
                                    style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0" }}
                                    fullWidth>
                                    <AddIcon style={{ marginRight: "8px" }} />
                                </Button>
                            </Box>
                        </AccordionSummary>
                    </Accordion>
                </Card>
                :
                <Card elevation={0} >
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="techniques">
                            {(provided) => (
                                <Card elevation={0} {...provided.droppableProps} ref={provided.innerRef}>
                                    {orderedTechniques.map((indexTechniquePair, index) => (
                                        <Draggable key={indexTechniquePair.technique.techniqueId} draggableId={indexTechniquePair.technique.techniqueId} index={index}>
                                            {(provided) => (
                                                <AccordionWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Accordion elevation={0} disableGutters square>
                                                        <AccordionSummary>
                                                            <Box display="flex" alignItems="center" justifyContent="space-between" width="97%">
                                                                <Box width="100%" display="flex" alignItems="center">
                                                                    <DragHandleIcon style={{ marginRight: "8px" }} />
                                                                    <Typography variant="body1">
                                                                        {indexTechniquePair.technique.title}
                                                                    </Typography>
                                                                </Box>
                                                                <IconButton
                                                                    onClick={e => { handleDelete({ index: indexTechniquePair.index, technique: indexTechniquePair.technique, id: indexTechniquePair.id }) }}
                                                                    sx={{ padding: "0px" }}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                </AccordionWrapper>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Card>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Accordion disableGutters square elevation={0}>
                        <AccordionSummary >
                            <Box display="flex" width="100%" alignItems="center" justifyContent='center'>
                                <Button
                                    onClick={handleDialogOpen}
                                    size="large"
                                    style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0" }}
                                    fullWidth
                                >
                                    <AddIcon style={{ marginRight: "8px" }} />
                                </Button>
                            </Box>
                        </AccordionSummary>
                    </Accordion>
                </Card>
            }
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                <CustomButton
                    type="submit"
                    onClick={(e) => { e.stopPropagation(); onDragDropSave(collection, orderedTechniques); }}>
                    Save
                </CustomButton>
                <CustomButton onClick={(e) => { e.stopPropagation(); onDragDropCancel(); }}>Cancel</CustomButton>
            </Box>
            {dialogComponent}
        </React.Fragment>
    );
}

export default DragDropTechniquesList;
