import { useSelector } from 'react-redux';
import Pageloader from '../../../components/Base/PageLoader';
import { StudentCollectionListWithFilters } from '../../../components/Lists/StudentCollectionListWithFilters';
import { RootState } from '../../../store/store';


function StudentCollections(): JSX.Element {
    const { selectedStudent } = useSelector((state: RootState) => state.student)

    return (
        <>
        {selectedStudent ? 
            <StudentCollectionListWithFilters selectedStudent={selectedStudent}/>
            : <Pageloader />
        }
        </>
    );
};

export default StudentCollections
