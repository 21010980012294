import { Role } from 'common';
import React from 'react';
import BaseLayout from '../../components/Base/BaseLayout';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import CoachTechniques from '../users/coach/Techniques';
import StudentTechniques from '../users/student/Techniques';


const TechniquesPage: React.FC = () => {
    const { data: user, isSuccess } = syllabusTrackerApi.useGetUserQuery()

    return (
        <BaseLayout text="Techniques">
            {isSuccess && (
                user.role === Role.Student ? <StudentTechniques />
                    : user.role === Role.Coach ? <CoachTechniques />
                        : user.role === Role.Admin && <p>Admin Techniques page placeholder</p>
            )}

        </BaseLayout>
    );
}


export default TechniquesPage;
