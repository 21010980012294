import { Box, CardContent, Typography, styled } from "@mui/material";
import MuiCard from '@mui/material/Card';
import syllabusTrackerApi from "../../services/syllabusTrackerApi";
import Pageloader from "../Base/PageLoader";
import CollectionList from "./Base Lists/CollectionList";
import CollectionFilter, { useHandleCollectionFilterChange } from "./List Filters/CollectionFilter";


const Card = styled(MuiCard)({
    '&.MuiCard-root': {
        marginLeft: "10px",
        marginTop: "10px",
        marginRight: "10px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

interface CollectionListWithFiltersProps {
    editable: boolean;
}

CollectionListWithFilters.defaultProps = {
    editable: false,
}

export function CollectionListWithFilters(props: CollectionListWithFiltersProps): JSX.Element {
    const { isLoading, isSuccess, isError, error } = syllabusTrackerApi.useGetCollectionsQuery()

    const { filteredCollections, handleCollectionFilterChange } = useHandleCollectionFilterChange()

    return (
        <>
            <Card>
                <CollectionFilter
                    onCollectionFiltersChange={handleCollectionFilterChange}
                />
            </Card>
            <Card>
                {isLoading ? <Pageloader />
                    : isSuccess ?
                        <Box>
                            <CollectionList
                                collections={filteredCollections}
                                editable={props.editable}
                            />
                        </Box>
                        : isError ?
                            <CardContent>
                                <Typography>{`Failed to fetch collections: ${error}`}</Typography>
                            </CardContent>
                            :
                            <CardContent>
                                <Typography>No collections available for selected filters.</Typography>
                            </CardContent>
                }
            </Card>
        </>

    )
};
