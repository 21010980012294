import StarBorderSharpIcon from '@mui/icons-material/StarBorderSharp';
import StarSharpIcon from '@mui/icons-material/StarSharp';
import { IconButton } from '@mui/material';


interface StarIconProps {
    filled: boolean;
    onClick: () => void
}

export function StarButton({ filled, onClick }: StarIconProps) {
    return (
        filled ?
            <IconButton onClick={onClick} color="inherit">
                <StarSharpIcon />
            </IconButton>
            : 
            <IconButton onClick={onClick} color="inherit">
                <StarBorderSharpIcon />
            </IconButton>
    )
}
