import { combineReducers, configureStore, Store } from "@reduxjs/toolkit";
import { syllabusTrackerApi } from "../services/syllabusTrackerApi";
import authReducer from '../slices/auth';
import studentReducer from '../slices/student';


const rootReducer = combineReducers({
    auth: authReducer,
    student: studentReducer,
    [syllabusTrackerApi.reducerPath]: syllabusTrackerApi.reducer    
});

export const store: Store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(syllabusTrackerApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
