import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle'; // Suitable drag handle
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { default as Button, ButtonProps, default as MuiButton } from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Collection, CollectionSet } from 'common';
import React from 'react';
import { renderAddCollectionToCollectionSetDialog } from '../../Dialogs/AddCollectionToCollectionSetDialog';


const Card = styled(MuiCard)({
    backgroundColor: "inherit",
});

const Accordion = styled(MuiAccordion)({
    backgroundColor: `#3c3836`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    }
});

const CustomButton = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", margin: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));

const AccordionWrapper = styled('div')({
    borderBottom: '1px solid #7c6f64'
});

interface DragDropTechniquesListProps {
    collectionSet: CollectionSet;
    onDragDropSave: (collectionSet: CollectionSet, collections: Collection[]) => void;
    onDragDropCancel: () => void;
}

function DragDropCollectionsList({ collectionSet, onDragDropSave, onDragDropCancel }: DragDropTechniquesListProps): JSX.Element {
    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)
    const initialCollections = collectionSet.collections
        ? collectionSet.collections.map((c, index) => ({ index, collection: c }))
        : [];
    const [orderedCollections, setOrderedCollections] = React.useState<{ index: number, collection: Collection }[]>(initialCollections);

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;
        const reorderedCollections = Array.from(orderedCollections);
        const [reorderedItem] = reorderedCollections.splice(result.source.index, 1);
        reorderedCollections.splice(result.destination.index, 0, reorderedItem);
        handleReorder(reorderedCollections);
    };

    const handleReorder = (newDragDropOrder: { index: number, collection: Collection }[]) => {
        const newOrder = newDragDropOrder.map((item, index) => {
            return { index, collection: item.collection }
        })
        setOrderedCollections(newOrder)
    }

    const handleDelete = (deletedCollection: { index: number, collection: Collection }) => {
        setOrderedCollections(orderedCollections.filter(item => item.collection !== deletedCollection.collection))
    };

    const handleDialogClose = () => {
        setDialogComponent(null)
    }

    const handleDialogSave = (selectedCollections: { index: number, collection: Collection }[]) => {
        const maxIndex = orderedCollections.length - 1
        const addedCollections = selectedCollections.map((indexCollectionPair, index) => {
            return { index: maxIndex + index, collection: indexCollectionPair.collection }
        })
        setOrderedCollections([...orderedCollections, ...addedCollections])
    }

    const handleDialogOpen = () => {
        setDialogComponent(renderAddCollectionToCollectionSetDialog(collectionSet, handleDialogClose, handleDialogSave))
    }

    return (
        <React.Fragment>
            {orderedCollections.length === 0 ?
                <Card elevation={0}>
                    <Accordion elevation={0} disableGutters square sx={{ borderBottom: '1px solid #7c6f64' }}>
                        <AccordionSummary>
                            <Typography variant="body1">
                                No collections in collection set
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    <Accordion disableGutters square elevation={0}>
                        <AccordionSummary >
                            <Box display="flex" width="100%" alignItems="center" justifyContent='center'>
                                <Button
                                    onClick={handleDialogOpen}
                                    size="large"
                                    style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0" }}
                                    fullWidth>
                                    <AddIcon style={{ marginRight: "8px" }} />
                                </Button>
                            </Box>
                        </AccordionSummary>
                    </Accordion>
                </Card>
                :
                <Card elevation={0} >
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="techniques">
                            {(provided) => (
                                <Card elevation={0} {...provided.droppableProps} ref={provided.innerRef}>
                                    {orderedCollections.map((indexCollectionPair, index) => (
                                        <Draggable key={indexCollectionPair.collection.collectionId} draggableId={indexCollectionPair.collection.collectionId} index={index}>
                                            {(provided) => (
                                                <AccordionWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Accordion elevation={0} disableGutters square>
                                                        <AccordionSummary>
                                                            <Box display="flex" alignItems="center" justifyContent="space-between" width="97%">
                                                                <Box width="100%" display="flex" alignItems="center">
                                                                    <DragHandleIcon style={{ marginRight: "8px" }} />
                                                                    <Typography variant="body1">
                                                                        {indexCollectionPair.collection.title}
                                                                    </Typography>
                                                                </Box>
                                                                <IconButton
                                                                    onClick={e => { handleDelete({ index: indexCollectionPair.index, collection: indexCollectionPair.collection }) }}
                                                                    sx={{ padding: "0px" }}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                </AccordionWrapper>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Card>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Accordion disableGutters square elevation={0}>
                        <AccordionSummary >
                            <Box display="flex" width="100%" alignItems="center" justifyContent='center'>
                                <Button
                                    onClick={handleDialogOpen}
                                    size="large"
                                    style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0" }}
                                    fullWidth
                                >
                                    <AddIcon style={{ marginRight: "8px" }} />
                                </Button>
                            </Box>
                        </AccordionSummary>
                    </Accordion>
                </Card>
            }
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                <CustomButton
                    type="submit"
                    onClick={(e) => { e.stopPropagation(); onDragDropSave(collectionSet, orderedCollections.map(c => c.collection)); }}>
                    Save
                </CustomButton>
                <CustomButton onClick={(e) => { e.stopPropagation(); onDragDropCancel(); }}>Cancel</CustomButton>
            </Box>
            {dialogComponent}
        </React.Fragment>
    );
}

export default DragDropCollectionsList;
