import { CardContent, styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { StudentTechnique, Technique, TechniqueStatus } from 'common';
import React from 'react';
import { Options, debounce } from 'ts-debounce';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import { FastTextField } from '../Fields/FastTextField';


const TextField = styled(FastTextField)({
    marginTop: "15px"
})

const Card = styled(MuiCard)({
    backgroundColor: `#3c3836`,
    '&.MuiCard-root': {
        margin: "10px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

const Button = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", margin: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));

function isStudentTechnique(technique: Object): technique is StudentTechnique {
    return 'studentTechniqueId' in technique
}


function isTechnique(technique: Object): technique is Technique {
    return 'techniqueId' in technique
}

export const renderEditNoteDialog = (technique: (Technique | StudentTechnique), onDialogClose: () => void, studentId: string, target?: ("coach" | "student")) => {
    if (target) {
        if (isStudentTechnique(technique)) {
            return <EditStudentTechniqueNoteDialog technique={technique} target={target} onDialogClose={onDialogClose} />
        } else {
            const newStudentTechnique: Partial<StudentTechnique> = { technique, userId: studentId, status: TechniqueStatus.Unassigned }
            return <EditStudentTechniqueNoteDialog technique={newStudentTechnique} target={target} onDialogClose={onDialogClose} />
        }
    } else if (isTechnique(technique)) {
        return <EditGlobalTechniqueNoteDialog technique={technique} onDialogClose={onDialogClose} />
    } else { throw new Error(`Error initialising technique note edit dialog`) }
}

interface EditStudentTechniqueNoteDialogProps {
    technique: Partial<StudentTechnique>;
    target: "student" | "coach";
    onDialogClose: () => void;
}

const EditStudentTechniqueNoteDialog = ({ technique, target, onDialogClose }: EditStudentTechniqueNoteDialogProps) => {
    const [wasSubmitted, setWasSubmitted] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(true)

    const [updateStudentTechnique] = syllabusTrackerApi.useEditStudentTechniqueMutation();
    const [postStudentTechnique] = syllabusTrackerApi.usePostStudentTechniqueMutation();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setWasSubmitted(true)
        if (e.currentTarget.checkValidity()) {
            const eventTarget = e.currentTarget
            if (target === "student") {
                technique.studentTechniqueId ? updateStudentTechnique({ ...technique, studentNotes: eventTarget.note.value })
                    : postStudentTechnique({ ...technique, studentNotes: eventTarget.note.value })
            } else if (target === "coach") {
                technique.studentTechniqueId ? updateStudentTechnique({ ...technique, coachNotes: eventTarget.note.value })
                    : postStudentTechnique({ ...technique, coachNotes: eventTarget.note.value })
            }
            setWasSubmitted(false)
            handleClose()
        } else {
            console.log("Form is invalid");
        }
    };

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedSubmit = debounce(handleSubmit, 5000, debounceOptions)

    const defaultValue = target === "student" ? technique.studentNotes : technique.coachNotes

    const handleClose = () => {
        onDialogClose()
        setDialogOpen(false)
        setWasSubmitted(false)
    }

    return (
        <>
            <Dialog open={dialogOpen} onClose={() => { setDialogOpen(false); handleClose() }} scroll="paper" maxWidth="md" fullWidth>
                <DialogTitle sx={{ padding: "0px", marginBottom: "10px" }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={0}>
                        <Button type="submit" form="noteEditForm" onClick={(event) => { event.stopPropagation(); }}>Save</Button>
                        <Button onClick={(event) => { event.stopPropagation(); handleClose() }}>Cancel</Button>
                    </Box>
                </DialogTitle>

                <DialogContent dividers={true} sx={{ padding: "0px", borderBottom: "none" }}>
                    <Card>
                        <CardContent>
                            <form noValidate id="noteEditForm" onSubmit={debouncedSubmit}>
                                <TextField wasSubmitted={wasSubmitted} size="small" fullWidth defaultValue={defaultValue || ""}
                                    multiline rows={8} name="note" label={target === "student" ? "Student Notes" : "Coach Notes"} />
                            </form>

                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </>
    )
};

interface EditGlobalTechniqueNoteDialogProps {
    technique: Technique;
    onDialogClose: () => void;
}

const EditGlobalTechniqueNoteDialog = ({ technique, onDialogClose }: EditGlobalTechniqueNoteDialogProps) => {
    const [wasSubmitted, setWasSubmitted] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(true)

    const [updateTechique] = syllabusTrackerApi.useEditTechniqueMutation();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setWasSubmitted(true)
        if (e.currentTarget.checkValidity()) {
            const target = e.currentTarget
            updateTechique({ ...technique, globalNotes: target.note.value })
            setWasSubmitted(false)
            setDialogOpen(false)
            onDialogClose()
        } else {
            console.log("Form is invalid");
        }
    };

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedSubmit = debounce(handleSubmit, 5000, debounceOptions)

    return (
        <>
            <Dialog open={dialogOpen} onClose={() => { setDialogOpen(false); onDialogClose() }} scroll="paper" maxWidth="md" fullWidth>
                <DialogTitle sx={{ padding: "0px", marginBottom: "10px" }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={0}>
                        <Button type="submit" form="techniqueEditForm" onClick={(event) => { event.stopPropagation(); }}>Save</Button>
                        <Button onClick={(event) => { event.stopPropagation(); setDialogOpen(false); onDialogClose() }}>Cancel</Button>
                    </Box>
                </DialogTitle>

                <DialogContent dividers={true} sx={{ padding: "0px", borderBottom: "none" }}>
                    <Card>
                        <CardContent>
                            <form noValidate id="techniqueEditForm" onSubmit={debouncedSubmit}>
                                <TextField wasSubmitted={wasSubmitted} size="small" fullWidth defaultValue={technique.globalNotes || ""}
                                    multiline rows={8} name="note" label="Global Notes" />
                            </form>

                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </>
    )
};
