import { User } from '@auth0/auth0-react';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pageloader from '../../../components/Base/PageLoader';
import { renderCreateUserDialog } from '../../../components/Dialogs/CreateUserDialog';
import StudentList from '../../../components/Lists/Base Lists/StudentList';
import syllabusTrackerApi from '../../../services/syllabusTrackerApi';
import { selectStudent } from '../../../slices/student';
import { AppDispatch } from '../../../store/store';


const Card = styled(MuiCard)({
    '&.MuiCard-root': {
        marginLeft: "10px",
        marginTop: "10px",
        marginRight: "10px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

const CoachStudents: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const handleNavigateToSelectedStudentHome = (student: User) => {
        dispatch(selectStudent(student))
        navigate('/student')
    }

    const { data: students, isLoading, isSuccess, error } = syllabusTrackerApi.useGetStudentsQuery()

    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)
    const [showFab, setShowFab] = React.useState(true)
    const handleDialogClose = () => { setDialogComponent(null); setShowFab(true) }

    const handleNewStudentClick = () => {
        setShowFab(false)
        setDialogComponent(renderCreateUserDialog(handleDialogClose))
    }

    return (
        <>
            {isLoading ? <Card><Pageloader /></Card>
                : isSuccess ? <Card><StudentList students={students} onSelectStudent={handleNavigateToSelectedStudentHome} /></Card>
                    : <Card><CardContent><Typography>{`Student data failed to fetch: ${error}`}</Typography></CardContent></Card>
            }
            <Fab
                color="primary"
                aria-label="add"
                style={{ position: 'fixed', bottom: '16px', right: '16px' }}
                onClick={handleNewStudentClick}
                hidden={!showFab} >
                <AddIcon />
            </Fab>
            {dialogComponent}
        </>
    )
}

export default CoachStudents
