import { CardContent, styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Collection } from 'common';
import React from 'react';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import theme from '../../theme/Theme';
import { transformCollectionForPut } from '../../util/Utilities';
import Pageloader from '../Base/PageLoader';
import { FastTextField } from '../Fields/FastTextField';
import { SelectField } from '../Fields/SelectField';
import { TextFieldWithDescriptionField } from '../Fields/TextFieldWithDescriptionField';
import { TitleTextField } from '../Fields/TitleTextField';
import { Options, debounce } from 'ts-debounce';


const TextField = styled(FastTextField)({
    marginTop: "15px"
})

const Card = styled(MuiCard)({
    backgroundColor: `#3c3836`,
    '&.MuiCard-root': {
        margin: "10px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

const Button = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", margin: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));

interface EditCollectionDialogProps {
    onClose: () => void;
    collection: Collection;
}

export const renderEditCollectionDialog = (collection: Collection, onClose: () => void) => {
    return <EditCollectionDialog collection={collection} onClose={onClose} />
}

const EditCollectionDialog = ({ collection, onClose }: EditCollectionDialogProps) => {
    const [isOpen, setIsOpen] = React.useState(true)
    const { data: collectionSuggestions, isLoading: suggestionsLoading, isSuccess: suggestionsSuccess } = syllabusTrackerApi.useGetCollectionSuggestionsQuery()
    const { data: descriptions, isLoading: descriptionsLoading, isSuccess: descriptionsSuccess } = syllabusTrackerApi.useGetDescriptionsQuery()
    const [updateCollection] = syllabusTrackerApi.useEditCollectionMutation();
    const [deleteCollection] = syllabusTrackerApi.useDeleteCollectionMutation();

    const [wasSubmitted, setWasSubmitted] = React.useState(false);
    const [localPositionState, setLocalPositionState] = React.useState(collection?.position?.title || '')

    const handlePositionBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const newPosition = event.target.value || '';
        setLocalPositionState(newPosition);
    };

    const isPositionOpenGuard = localPositionState.toLowerCase() === 'open guard';

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setWasSubmitted(true)
        if (event.currentTarget.checkValidity()) {
            const formData = new FormData(event.currentTarget)
            const fieldValues = Object.fromEntries(formData.entries());
            const fieldValuesWithIdAndTechniques = {
                ...fieldValues,
                collectionId: collection.collectionId,
                collectionTechniques: collection.collectionTechniques
            }
            updateCollection(transformCollectionForPut(fieldValuesWithIdAndTechniques))
            setWasSubmitted(false)
            handleClose()
        } else {
            console.log("Form is invalid");
        }
    };

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedSubmit = debounce(handleSubmit, 5000, debounceOptions)

    const handleClose = () => {
        onClose()
        setIsOpen(false)
        setWasSubmitted(false)
    }

    return (
        <>
            {(suggestionsLoading || descriptionsLoading) ? <CardContent><Pageloader /></CardContent>
                : (suggestionsSuccess && descriptionsSuccess) &&
                <Dialog open={isOpen} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
                    <DialogTitle sx={{ padding: "0px", marginBottom: "10px" }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={0}>
                            <Button type="submit" form="collectionEditForm" onClick={(event) => { event.stopPropagation(); }}>Save</Button>
                            <Button onClick={(event) => { event.stopPropagation(); handleClose(); }}>Cancel</Button>
                            <Button onClick={(event) => { event.stopPropagation(); deleteCollection(collection.collectionId); handleClose() }}
                                style={{ backgroundColor: theme.palette.error.main }}
                            >Delete</Button>
                        </Box>
                    </DialogTitle>

                    <DialogContent dividers={true} sx={{ padding: "0px", borderBottom: "none" }}>
                        <Card>
                            <CardContent>
                                <form id="collectionEditForm" onSubmit={debouncedSubmit}>

                                    <TitleTextField wasSubmitted={wasSubmitted} size="small" fullWidth required defaultValue={collection?.title || ''}
                                        name="title" label="Collection Title" options={collectionSuggestions.title} />

                                    <TextField wasSubmitted={wasSubmitted} size="small" fullWidth required defaultValue={collection?.description}
                                        multiline rows={4} name="description" label="Collection Description" />

                                    <TextField wasSubmitted={wasSubmitted} size="small" fullWidth defaultValue={collection?.globalNotes || ''}
                                        multiline rows={4} name="globalNotes" label="Global Notes" />

                                    <TextFieldWithDescriptionField wasSubmitted={wasSubmitted} size="small" fullWidth name="position"
                                        label="Position" descriptionLabel="Position Description" options={collectionSuggestions.position}
                                        descriptions={descriptions} onPositionBlur={handlePositionBlur} defaultValue={collection?.position?.title || ''}
                                        descriptionDefaultValue={collection?.position?.description || ''} />

                                    <SelectField wasSubmitted={wasSubmitted} name="hierarchy" label="Hierarchy" defaultValue={collection?.hierarchy || ''}
                                        options={collectionSuggestions.hierarchy} />

                                    <TextFieldWithDescriptionField wasSubmitted={wasSubmitted} size="small" fullWidth name="type"
                                        defaultValue={collection?.type?.title || ''} descriptionDefaultValue={collection?.type?.description || ''}
                                        label="Type" descriptionLabel="Type Description" options={collectionSuggestions.type} descriptions={descriptions} />

                                    <SelectField wasSubmitted={wasSubmitted} name="gi" label="Gi" defaultValue={collection?.gi || ''}
                                        options={collectionSuggestions.gi} />

                                    <TextFieldWithDescriptionField wasSubmitted={wasSubmitted} size="small" fullWidth name="openGuard"
                                        defaultValue={collection?.openGuard?.title || ''} descriptionDefaultValue={collection?.openGuard?.description || ''}
                                        label="Open Guard" descriptionLabel="Open Guard Description" options={collectionSuggestions.openguard}
                                        descriptions={descriptions} hidden={!isPositionOpenGuard} disabled={!isPositionOpenGuard} required={isPositionOpenGuard} />

                                </form>
                            </CardContent>
                        </Card>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}
