import { CardContent, styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CollectionSet } from 'common';
import React from 'react';
import { Options, debounce } from 'ts-debounce';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import theme from '../../theme/Theme';
import { transformCollectionSetForPut } from '../../util/Utilities';
import { FastTextField } from '../Fields/FastTextField';
import { TitleTextField } from '../Fields/TitleTextField';


const TextField = styled(FastTextField)({
    marginTop: "15px"
})

const Card = styled(MuiCard)({
    backgroundColor: `#3c3836`,
    '&.MuiCard-root': {
        margin: "10px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

const Button = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", margin: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));

interface EditCollectionSetDialogProps {
    onClose: () => void;
    collectionSet: CollectionSet;
}

export const renderEditCollectionSetDialog = (collectionSet: CollectionSet, onClose: () => void) => {
    return <EditCollectionSetDialog collectionSet={collectionSet} onClose={onClose} />
}

const EditCollectionSetDialog = ({ collectionSet, onClose }: EditCollectionSetDialogProps) => {
    const [isOpen, setIsOpen] = React.useState(true)
    const [updateCollectionSet] = syllabusTrackerApi.useEditCollectionSetMutation();
    const [deleteCollectionSet] = syllabusTrackerApi.useDeleteCollectionSetMutation();

    const [wasSubmitted, setWasSubmitted] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setWasSubmitted(true)
        if (event.currentTarget.checkValidity()) {
            const formData = new FormData(event.currentTarget)
            const fieldValues = Object.fromEntries(formData.entries());
            const fieldValuesWithIdAndCollections = {
                ...fieldValues,
                collectionSetId: collectionSet.collectionSetId,
                collections: collectionSet.collections
            }
            updateCollectionSet(transformCollectionSetForPut(fieldValuesWithIdAndCollections))
            setWasSubmitted(false)
            handleClose()
        } else {
            console.log("Form is invalid");
        }
    };

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedSubmit = debounce(handleSubmit, 5000, debounceOptions)

    const handleClose = () => {
        onClose()
        setIsOpen(false)
        setWasSubmitted(false)
    }

    return (
        <>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} scroll="paper" maxWidth="md" fullWidth>
                <DialogTitle sx={{ padding: "0px", marginBottom: "10px" }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={0}>
                        <Button type="submit" form="collectionEditForm" onClick={(event) => { event.stopPropagation(); }}>Save</Button>
                        <Button onClick={(event) => { event.stopPropagation(); handleClose(); }}>Cancel</Button>
                        <Button onClick={(event) => { event.stopPropagation(); deleteCollectionSet(collectionSet.collectionSetId); handleClose() }}
                            style={{ backgroundColor: theme.palette.error.main }}
                        >Delete</Button>
                    </Box>
                </DialogTitle>

                <DialogContent dividers={true} sx={{ padding: "0px", borderBottom: "none" }}>
                    <Card>
                        <CardContent>
                            <form id="collectionEditForm" onSubmit={debouncedSubmit}>

                                <TitleTextField wasSubmitted={wasSubmitted} size="small" fullWidth required defaultValue={collectionSet?.title || ''}
                                    name="title" label="Collection Title" options={[]} />

                                <TextField wasSubmitted={wasSubmitted} size="small" fullWidth required defaultValue={collectionSet?.description}
                                    multiline rows={4} name="description" label="Collection Description" />

                            </form>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </>
    )
}
