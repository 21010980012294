import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Collection, Technique } from 'common';
import React from 'react';
import TechniqueList from '../Lists/Base Lists/TechniqueList';
import TechniqueFilter, { useHandleTechniqueFilterChange } from '../Lists/List Filters/TechniqueFilter';
import { renderNewTechniqueDialog } from './NewTechniqueDialog';
import { Options, debounce } from 'ts-debounce';


const Card = styled(MuiCard)({
    backgroundColor: `#3c3836`,
    '&.MuiCard-root': {
        margin: "10px",
        marginBottom: "0px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

interface FiltersObject {
    title: string | null,
    hierarchy: string | null,
    type: string | null,
    position: string | null,
    openGuard: string | null,
    gi: string | null;
}

const Button = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", marginX: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));

interface AddTechniqueToCollectionDialogProps {
    onClose: () => void;
    onSave: (selectedTechniques: { index: number, technique: Technique }[]) => void;
    collection: Collection;
};

export const renderAddTechniqueToCollectionDialog = (
    collection: Collection, 
    onClose: () => void, 
    onSave: (selectedTechniques: { index: number, technique: Technique }[]) => void
    ) => {
    return <AddTechniqueToCollectionDialog collection={collection} onSave={onSave} onClose={onClose} />
}

const AddTechniqueToCollectionDialog = ({ onClose, onSave, collection }: AddTechniqueToCollectionDialogProps) => {
    const [isOpen, setIsOpen] = React.useState(true)
    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)
    const [cleanedTechniques, setCleanedTechniques] = React.useState<Technique[]>([]);
    const { filteredTechniques, handleTechniqueFilterChange } = useHandleTechniqueFilterChange()
    const [checkedArray, setCheckedArray] = React.useState<boolean[]>(
        filteredTechniques.map(t => { return false })
    )

    React.useEffect(() => {
        setCleanedTechniques(filteredTechniques.filter(t => !collection.collectionTechniques?.some(ct => ct.technique.techniqueId === t.techniqueId)));
    }, [filteredTechniques, setCleanedTechniques, collection.collectionTechniques])

    const handleTechniqueCheck = (checkedTechnique: Technique) => {
        const index = cleanedTechniques.findIndex(technique =>
            technique.techniqueId === checkedTechnique.techniqueId
        )
        setCheckedArray(prevChecked => {
            const newChecked = [...prevChecked]
            newChecked[index] = !newChecked[index]
            return newChecked
        });
    };

    const handleTechniqueFilterMatchClick = (collection: Collection): FiltersObject => {
        return {
            title: null,
            hierarchy: collection.hierarchy || null,
            type: collection.type?.title || null,
            position: collection.position?.title || null,
            openGuard: collection.openGuard?.title || null,
            gi: collection.gi || null
        }
    }

    const handleAddTechniques = () => {
        let selectedTechniques: { index: number, technique: Technique }[] = cleanedTechniques
            .map((technique, index) => ({ index, technique, checked: checkedArray[index] }))
            .filter(item => item.checked);

        setCleanedTechniques(prevCleanedTechniques => {
            return prevCleanedTechniques.filter(technique =>
                !selectedTechniques.some(st => st.technique.techniqueId === technique.techniqueId)
            )
        })
        setCheckedArray([])
        onSave(selectedTechniques)
        handleClose()
    }

    const handleNewTechniqueClick = () => {
        setDialogComponent(renderNewTechniqueDialog(handleDialogClose))
    }

    const handleDialogClose = () => {
        setDialogComponent(null)
    }

    const handleClose = () => {
        setDialogComponent(null)
        setIsOpen(false)
        onClose();
    }

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedAddTechniques = debounce(handleAddTechniques, 5000, debounceOptions)

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
                <DialogTitle sx={{ padding: "0px", marginBottom: "10px" }}>
                    <Card>
                        <TechniqueFilter
                            onTechniqueFiltersChange={handleTechniqueFilterChange}
                            matchTechniqueFilters={handleTechniqueFilterMatchClick(collection)} />
                    </Card>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                            <Button disabled={(checkedArray.length === 0)} onClick={(event) => { event.stopPropagation(); debouncedAddTechniques(); handleClose(); }}>Add</Button>
                            <Button onClick={(event) => { event.stopPropagation(); handleClose() }}>Cancel</Button>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                            <Button onClick={handleNewTechniqueClick}>Create New Technique</Button>
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent dividers={true} sx={{ padding: "0px" }}>
                    <Card>
                        <TechniqueList
                            filteredTechniques={cleanedTechniques ?? filteredTechniques}
                            checkbox
                            elevation={1}
                            checkedArray={checkedArray}
                            onTechniqueCheck={handleTechniqueCheck}
                        />
                    </Card>
                    <div style={{ paddingTop: "10px" }} />
                </DialogContent>
            </Dialog>
            {dialogComponent}
        </>
    )
};
