import { Role } from 'common';
import React from 'react';
import BaseLayout from '../../components/Base/BaseLayout';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import CoachStudents from '../users/coach/Students';


const StudentsPage: React.FC = () => {
    const { data: user, isSuccess } = syllabusTrackerApi.useGetUserQuery();

    return (
        <BaseLayout text="Students">
            {isSuccess && (
                user.role === Role.Student ? <p>Invalid permissions</p>
                    : user.role === Role.Coach ? <CoachStudents />
                        : user.role === Role.Admin && <p>Admin Collections page placeholder</p>
            )}

        </BaseLayout>
    );
}


export default StudentsPage;
