import { CollectionListWithFilters } from '../../../components/Lists/CollectionListWithFilters';


function CoachCollections(): JSX.Element {
    return (
        <CollectionListWithFilters
            editable
        />
    );
};

export default CoachCollections
