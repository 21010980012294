import Pageloader from '../../../components/Base/PageLoader';
import { StudentTechniqueListWithFilters } from '../../../components/Lists/StudentTechniqueListWithFilters';
import syllabusTrackerApi from '../../../services/syllabusTrackerApi';


function StudentTechniques(): JSX.Element {
    const { data: user, isLoading, isSuccess } = syllabusTrackerApi.useGetUserQuery();

    return (
        <> {isLoading ? <Pageloader />
            : isSuccess && <StudentTechniqueListWithFilters selectedStudent={user} />
        }
        </>
    );
};

export default StudentTechniques
