import { User as Auth0User } from '@auth0/auth0-react';
import { Collection, CollectionSet, CollectionTechnique, Gi, Hierarchy, Role, Technique } from "common";


type UpdateCollection = Partial<Pick<Collection, Exclude<keyof Collection, 'collectionTechniques'>>> & {
    collectionTechniques?: Partial<CollectionTechnique>[];
};

export const decodeAndAddRole = (userToken: Auth0User): Auth0User => {
    if (!Object.values(Role).includes(userToken[`https://syllabustracker.matthewtapps.com/roles`][0])) {
        throw new Error('Invalid user role attached to id token')
    }

    const user = {
        ...userToken,
        role: userToken['https://syllabustracker.matthewtapps.com/roles'][0] as Role,
    }

    return user
}

export const stripIds = (user: Auth0User): Auth0User => {
    let newUser = {...user}
    
    if (user.user_id) {
        newUser.user_id = stripAuth0FromUserId(user.user_id)
        newUser.sub = stripAuth0FromUserId(user.user_id)
    }

    if (user.sub) {
        newUser.sub = stripAuth0FromUserId(user.sub)
        newUser.user_id = stripAuth0FromUserId(user.sub)
    }

    return newUser
}

export const transformTechniqueForPost = (technique: any): Partial<Technique> => {
    if (!Object.values(Gi).includes(technique.gi)) {
        throw new Error(`Invalid Gi value`)
    }

    if (!Object.values(Hierarchy).includes(technique.hierarchy)) {
        throw new Error(`Invalid Hierarchy value`)
    }

    let videos: {title: string, hyperlink: string}[] = []

    const videoKeys = Object.keys(technique).filter(key => key.startsWith("video"));
    const videoTitles = videoKeys.filter(key => key.includes("title"));

    videoTitles.forEach(titleKey => {
        const index = titleKey.split("_")[2]; // Assuming format video_title_0, video_title_1, etc.
        const linkKey = `video_link_${index}`;
        if ((technique[titleKey] && technique[linkKey]) && technique[titleKey].length > 0 && technique[linkKey].length > 0) {
            videos.push({ title: technique[titleKey], hyperlink: technique[linkKey] });
        }
    });

    const transformedTechnique: Partial<Technique> = {
        title: technique.title,
        videos: videos.length > 0 ? videos : null,
        description: technique.description,
        globalNotes: technique.globalNotes,
        gi: technique.gi as Gi || null,
        hierarchy: technique.hierarchy as Hierarchy || null,
        type: {
            title: technique.type,
            description: technique.typeDescription
        },
        position: {
            title: technique.position,
            description: technique.positionDescription
        },
        openGuard: technique.openGuard ? {
            title: technique.openGuard,
            description: technique.openGuardDescription
        } : null,
    }

    return transformedTechnique;
};

export const transformTechniqueForPut = (technique: any): Partial<Technique> => {
    if (!Object.values(Gi).includes(technique.gi)) {
        throw new Error(`Invalid Gi value`)
    }

    if (!Object.values(Hierarchy).includes(technique.hierarchy)) {
        throw new Error(`Invalid Hierarchy value`)
    }

    let videos: {title: string, hyperlink: string}[] = []

    const videoKeys = Object.keys(technique).filter(key => key.startsWith("video"));
    const videoTitles = videoKeys.filter(key => key.includes("title"));

    videoTitles.forEach(titleKey => {
        const index = titleKey.split("_")[2]; // Assuming format video_title_0, video_title_1, etc.
        const linkKey = `video_link_${index}`;
        if ((technique[titleKey] && technique[linkKey]) && technique[titleKey].length > 0 && technique[linkKey].length > 0) {
            videos.push({ title: technique[titleKey], hyperlink: technique[linkKey] });
        }
    });

    let transformedTechnique: Partial<Technique> = {
        techniqueId: technique.techniqueId,
        title: technique.title,
        videos: videos.length > 0 ? videos : null,
        description: technique.description,
        globalNotes: technique.globalNotes,
        gi: technique.gi as Gi || null,
        hierarchy: technique.hierarchy as Hierarchy || null,
        type: {
            title: technique.type,
            description: technique.typeDescription
        },
        position: {
            title: technique.position,
            description: technique.positionDescription
        },
        openGuard: technique.openGuard ? {
            title: technique.openGuard,
            description: technique.openGuardDescription
        } : null,
    };

    return transformedTechnique;
};

export const transformCollectionForPost = (collection: any): Partial<Collection> => {
    const transformedCollection: Partial<Collection> = {
        title: collection.title,
        description: collection.description,
        globalNotes: collection.globalNotes || null,
        gi: collection.gi as Gi || null,
        hierarchy: collection.hierarchy as Hierarchy || null,
        type: collection.type ? {
            title: collection.type,
            description: collection.typeDescription
        } : null,
        position: collection.position ? {
            title: collection.position,
            description: collection.positionDescription
        } : null,
        openGuard: collection.openGuard ? {
            title: collection.openGuard,
            description: collection.openGuardDescription
        } : null,
    };
    
    return transformedCollection;
};

export const transformCollectionForPut = (collection: any): UpdateCollection => {
    if (!collection.title) {
        throw new Error(`Collection title missing`)
    }

    if (!collection.description) {
        throw new Error(`Collection description missing`)
    }

    if (collection.gi && !Object.values(Gi).includes(collection.gi)) {
        throw new Error(`Gi value is invalid`)
    }

    if (collection.hierarchy && !Object.values(Hierarchy).includes(collection.hierarchy)) {
        throw new Error(`Hierarchy value is invalid`)
    }

    const transformedCollection: UpdateCollection = {
        collectionId: collection.collectionId,
        title: collection.title,
        description: collection.description,
        globalNotes: collection.globalNotes || null,
        gi: collection.gi as Gi || null,
        hierarchy: collection.hierarchy as Hierarchy || null,
        collectionTechniques: collection.collectionTechniques,
        type: collection.type ? {
            title: collection.type,
            description: collection.typeDescription
        } : null,
        position: collection.position ? {
            title: collection.position,
            description: collection.positionDescription
        } : null,
        openGuard: collection.openGuard ? {
            title: collection.openGuard,
            description: collection.openGuardDescription
        } : null,
    };

    return transformedCollection;
};

export const transformCollectionSetForPut = (collectionSet: any): Partial<CollectionSet> => {
    if (!collectionSet.title) {
        throw new Error(`Collection Set title missing`)
    }

    if (!collectionSet.description) {
        throw new Error(`Collection Set description missing`)
    }


    const transformedCollectionSet: Partial<CollectionSet> = {
        collectionSetId: collectionSet.collectionSetId,
        title: collectionSet.title,
        description: collectionSet.description,
        collections: collectionSet.collections ?? null,
    };

    return transformedCollectionSet;
};

export const transformCollectionSetForPost = (collectionSet: any): Partial<CollectionSet> => {
    if (!collectionSet.title) {
        throw new Error(`Collection Set title missing`)
    }

    if (!collectionSet.description) {
        throw new Error(`Collection Set description missing`)
    }


    const transformedCollectionSet: Partial<CollectionSet> = {
        title: collectionSet.title,
        description: collectionSet.description,
        collections: collectionSet.collections ?? null,
    };

    return transformedCollectionSet;
};

export const stripAuth0FromUserId = (id: string): string => {
    return id.replace("auth0|", "")
};

export const downloadJson = (data: any, fileName: any) => {
    const jsonString = JSON.stringify(data, null, 2); // Pretty print JSON
    const blob = new Blob([jsonString], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = href;
    link.download = `${fileName}.json`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
