import MuiArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { TechniqueStatus } from "common";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import syllabusTrackerApi from '../../../services/syllabusTrackerApi';


const Typography = styled(MuiTypography)({
    '&.DashboardCard-heading': {
        padding: "10px",
    },
})

const Card = styled(MuiCard)({
    '&.MuiCard-root': {
        backgroundColor: "#3c3836",
        border: "1px",
        borderRadius: "2",
    }
});

const ArrowForwardIosIcon = styled(MuiArrowForwardIosIcon)({
    paddingRight: "8px",
    fontSize: "2em"
});

const StudentDashboard: React.FC = () => {

    const { data: user } = syllabusTrackerApi.useGetUserQuery();
    const { data: userTechniques } = syllabusTrackerApi.useGetSelectedStudentTechniquesQuery(user?.user_id ? user?.user_id : 'none')
    let chartData: { id: number, value: number, color: string, label: string }[] = [
        { id: 0, value: 1, color: '#282828', label: "Not Yet Started" },
        { id: 1, value: 1, color: '#d79921', label: "Started" },
        { id: 2, value: 1, color: '#689d6a', label: "Passed" },
    ]

    if (userTechniques) {
        let started = 0
        let notYetStarted = 0
        let passed = 0
        userTechniques.filter(t => t.status !== TechniqueStatus.Unassigned).forEach(t => {
            if (t.status === TechniqueStatus.NotYetStarted) notYetStarted++
            if (t.status === TechniqueStatus.Started) started++
            if (t.status === TechniqueStatus.Passed) passed++

            chartData = [
                { id: 0, value: notYetStarted, color: '#282828', label: "Not Yet Started" },
                { id: 1, value: started, color: '#d79921', label: "Started" },
                { id: 2, value: passed, color: '#689d6a', label: "Passed" },
            ]
        })
    }

    const navigate = useNavigate();
    const navigateToTechniques = () => { navigate('/techniques') }
    const navigateToCollections = () => { navigate('/collections') }

    return (
        <div>
            <Grid container spacing={1} padding={1}>
                <Grid item xs={12}>
                    <Card>
                        <Typography variant='h6' className="DashboardCard-heading">Syllabus Progress</Typography>
                        <Box height={300}>
                            <PieChart
                                margin={{
                                    left: 0,
                                    top: 0,
                                    right: 0,
                                    bottom: 10
                                }}
                                series={[
                                    {
                                        data: chartData,
                                        innerRadius: 30,
                                        paddingAngle: 5,
                                        startAngle: -90,
                                        endAngle: 90,
                                        outerRadius: "120%",
                                        cy: "70%",
                                        arcLabel: (item) => `${item.value}`,
                                        arcLabelMinAngle: 45,
                                    }
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                    },
                                }}
                                slotProps={{
                                    legend: {
                                        direction: 'row',
                                        position: { vertical: 'bottom', horizontal: 'middle' },
                                        padding: 30
                                    }
                                }}
                            />
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card onClick={navigateToTechniques}>
                        <CardActionArea>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant='h6' className="DashboardCard-heading">Techniques</Typography>
                                <ArrowForwardIosIcon />
                            </Box>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card onClick={navigateToCollections}>
                        <CardActionArea>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant='h6' className="DashboardCard-heading">Collections</Typography>
                                <ArrowForwardIosIcon />
                            </Box>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default StudentDashboard;
