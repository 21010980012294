import { Menu, MenuItem, SvgIcon } from "@mui/material";
import { TechniqueStatus } from 'common';
import { useState } from "react";


export enum Option {
    Unassign = "Unassign",
    Assign = "Assign",
    Passed = "Passed",
    Started = "Started",
}

interface ProgressBarIconProps {
    statuses: TechniqueStatus[];
    onClick?: (event: any) => void;
    onMenuItemClick?: (option: Option) => void;
}

export function ProgressBarIcon(props: ProgressBarIconProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        props.onClick?.(event)
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null)
        }
    };

    const handleClose = (event: any) => {
        setAnchorEl(null);
        event.stopPropagation()
    };

    const handleInternalMenuItemClick = (option: Option) => {
        props.onMenuItemClick?.(option)
        handleClose(new Event('Custom'));
    };

    const maximum = props.statuses.length
    const startedPercentage = props.statuses.filter(status => status === TechniqueStatus.Started || status === TechniqueStatus.Passed).length / maximum
    const passedPercentage = props.statuses.filter(status => status === TechniqueStatus.Passed).length / maximum

    const viewBoxWidth = 2800;
    const baseWidth = viewBoxWidth * .9833
    const startedWidth = baseWidth * (startedPercentage || 0) * .997
    const passedWidth = baseWidth * (passedPercentage || 0) * .997

    const viewBoxHeight = 100
    const baseHeight = viewBoxHeight * .8
    const progressHeight = baseHeight * .898

    const rx = viewBoxHeight * .375

    return (
        <>
            <SvgIcon viewBox={`-6 -6 2800 100`} style={{width: "100%", height: "100%"}} onClick={e => { handleClick(e) }}>
                    <rect rx={rx} fill="#665c54" width={baseWidth} height={baseHeight} stroke="#bdae93" strokeWidth="8px"/>
                    <rect y="4" x="4" rx={rx} fill="#d79921" width={startedWidth} height={progressHeight}/>
                    <rect y="4" x="4" rx={rx} fill="#689d6a" width={passedWidth} height={progressHeight}/>
            </SvgIcon>
            {props.onClick &&
                <Menu
                    id="context-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={(event) => { event.stopPropagation(); handleInternalMenuItemClick(Option.Unassign) }}>Unassigned</MenuItem>
                    <MenuItem onClick={(event) => { event.stopPropagation(); handleInternalMenuItemClick(Option.Assign); }}>Assigned</MenuItem>
                    <MenuItem onClick={(event) => { event.stopPropagation(); handleInternalMenuItemClick(Option.Started) }}>Started</MenuItem>
                    <MenuItem onClick={(event) => { event.stopPropagation(); handleInternalMenuItemClick(Option.Passed) }}>Passed</MenuItem>
                </Menu>
            }
        </>

    );
}
