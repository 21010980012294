import { User } from "@auth0/auth0-react";
import React from "react";
import BaseLayout from "../../components/Base/BaseLayout";
import ProfileComponent from "../users/common/Profile";


export const ProfilePage: React.FC = (user: User) => {
    return (
        <BaseLayout text="Profile">
            <ProfileComponent/>
        </BaseLayout>
    );
};
