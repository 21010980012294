import { User } from '@auth0/auth0-react';
import { Edit, VideocamSharp } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Button, CardContent } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { default as MuiCard } from '@mui/material/Card';
import MuiLinearProgress from '@mui/material/LinearProgress';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Role, StudentTechnique, Technique, TechniqueStatus } from 'common';
import React from 'react';
import syllabusTrackerApi from '../../../services/syllabusTrackerApi';
import Pageloader from '../../Base/PageLoader';
import { ParagraphSpacer } from '../../Base/ParagraphSpacer';
import { CircleIcon, Option } from '../../Buttons/CircleIcon';
import { StarButton } from '../../Buttons/StarIcon';
import { renderEditNoteDialog } from '../../Dialogs/EditNoteDialogs';
import { renderVideoModal } from '../../Modals/VideoModal';


const Accordion = styled(MuiAccordion)({
    backgroundColor: `#3c3836`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    },
    '&:not(:last-child)': {
        borderBottom: '1px solid #7c6f64'
    }
});

const SubAccordion = styled(MuiAccordion)({
    backgroundColor: `inherit`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    }
});

const ListItem = styled(MuiListItem)({
    paddingTop: "0px",
    paddingLeft: "0px",
    '&.MuiListItem-root.Mui-selected': {
        backgroundColor: 'inherit'
    }
})

const LinearProgress = styled(MuiLinearProgress)({
    '.MuiLinearProgress-bar': {
        transition: 'none'
    }
})

interface ExtendedListItemTextProps extends ListItemTextProps {
    smalltext: boolean
}

const BaseListItemText: React.FC<ExtendedListItemTextProps> = (props) => {
    const { smalltext, ...otherProps } = props;
    return (
        <MuiListItemText
            {...otherProps}
            secondaryTypographyProps={{ component: 'div' }}
            primaryTypographyProps={{ component: 'div' }}
        />
    );
}

const ListItemText = styled(BaseListItemText)<ExtendedListItemTextProps>(({ theme, smalltext }) => {
    let primaryVariant = 'h6';
    let secondaryVariant = 'body1';

    if (smalltext) {
        primaryVariant = 'body1';
        secondaryVariant = 'body2';
    }

    return {
        '& .MuiTypography-root': {
            variant: primaryVariant
        },
        '& .MuiTypography-colorTextSecondary': {
            variant: secondaryVariant
        }
    };
});

const SubCard = styled(MuiCard)({
    backgroundColor: 'inherit',
})

interface StudentTechniqueListProps {
    filteredTechniques?: Technique[];
    ordered?: boolean;
    elevation: number;
    editable?: boolean;
    selectedStudent: User;
}

StudentTechniqueList.defaultProps = {
    elevation: 3,
    ordered: false,
    editable: false,
}

function StudentTechniqueList({ selectedStudent, ...props }: StudentTechniqueListProps): JSX.Element {
    const { data: user } = syllabusTrackerApi.useGetUserQuery();
    const { data: techniques, isLoading, isSuccess, isError, error } = syllabusTrackerApi.useGetTechniquesQuery()
    const { data: selectedStudentTechniques, isLoading: stLoading, isSuccess: stSuccess } = syllabusTrackerApi.useGetSelectedStudentTechniquesQuery(selectedStudent.user_id || selectedStudent.sub)
    const [postStudentTechnique, { isLoading: isPostingStudentTechnique }] = syllabusTrackerApi.usePostStudentTechniqueMutation()
    const [updateStudentTechnique] = syllabusTrackerApi.useEditStudentTechniqueMutation()

    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)

    const determineIconColor = (techniqueStatus: TechniqueStatus | undefined): string => {
        if (techniqueStatus === TechniqueStatus.Started) {
            return "#d79921";
        } else if (techniqueStatus === TechniqueStatus.Passed) {
            return "#689d6a";
        } else {
            return "#665c54";
        }
    }

    const techniquesToDisplay: Technique[] = props.filteredTechniques || techniques || []

    const handleIndicatorFill = (technique: Technique) => {
        if (stSuccess && selectedStudentTechniques) {
            const matchingTechnique = selectedStudentTechniques.find(st =>
                st.technique.techniqueId === technique.techniqueId
            )
            return determineIconColor(matchingTechnique?.status)
        } else return "#665c54"
    }

    const statusActions = {
        [Option.Assign]: async (technique: Technique) => {
            const matchedTechnique = selectedStudentTechniques!.find(st => st.technique.techniqueId === technique.techniqueId)
            matchedTechnique ? updateStudentTechnique({ ...matchedTechnique, status: TechniqueStatus.NotYetStarted })
                : postStudentTechnique({ userId: selectedStudent.user_id, technique: technique, status: TechniqueStatus.NotYetStarted })
        },
        [Option.Started]: async (technique: Technique) => {
            const matchedTechnique = selectedStudentTechniques!.find(st => st.technique.techniqueId === technique.techniqueId)
            matchedTechnique ? updateStudentTechnique({ ...matchedTechnique, status: TechniqueStatus.Started })
                : postStudentTechnique({ userId: selectedStudent.user_id, technique: technique, status: TechniqueStatus.Started })
        },
        [Option.Passed]: async (technique: Technique) => {
            const matchedTechnique = selectedStudentTechniques!.find(st => st.technique.techniqueId === technique.techniqueId)
            matchedTechnique ? updateStudentTechnique({ ...matchedTechnique, status: TechniqueStatus.Passed })
                : postStudentTechnique({ userId: selectedStudent.user_id, technique: technique, status: TechniqueStatus.Passed })
        },
        [Option.Unassign]: async (technique: Technique) => {
            const matchedTechnique = selectedStudentTechniques!.find(st => st.technique.techniqueId === technique.techniqueId)
            matchedTechnique ? updateStudentTechnique({ ...matchedTechnique, status: TechniqueStatus.Unassigned })
                : postStudentTechnique({ userId: selectedStudent.user_id, technique: technique, status: TechniqueStatus.Unassigned })
        }
    };

    const handleStatusAction = (technique: Technique) => async (option: Option) => {
        if (stSuccess) {
            const action = statusActions[option];
            await action(technique);
        }
    };

    const handleNoteDialogClose = () => { setDialogComponent(null) }

    const [videoModalComponent, setVideoModalComponent] = React.useState<JSX.Element | null>(null)

    const handleVideoModalClose = () => { setVideoModalComponent(null) }

    return (
        <>
            {
                (isLoading || stLoading) ? <Pageloader />
                    : (isSuccess && stSuccess) ?
                        techniquesToDisplay.length > 0 ?
                            techniquesToDisplay.map((technique, index) => {
                                const matchedStudentTechnique: StudentTechnique | undefined = (
                                    selectedStudentTechniques ?
                                        selectedStudentTechniques.find(st => { return technique.techniqueId === st.technique.techniqueId })
                                        : undefined
                                )
                                let currentOrder = props.ordered ? index + 1 : null
                                return (
                                    <Accordion disableGutters elevation={props.elevation} key={technique.techniqueId}>
                                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" >
                                            <Box display="flex" flexDirection="row" width="100%">
                                                {isPostingStudentTechnique && <LinearProgress style={{ width: "100%" }} />}
                                                <Box display="flex" flexDirection="column" flexGrow={1}>
                                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="97%">
                                                        <Box display="flex" alignItems="center" marginLeft="0px">
                                                            <StarButton filled={false} onClick={handleNoteDialogClose} />
                                                            {props.ordered && !isPostingStudentTechnique && (
                                                                <Typography variant="body1" style={{ marginRight: "8px" }}>{currentOrder + ". "}</Typography>
                                                            )}
                                                            <Typography variant="body1">{!isPostingStudentTechnique && technique?.title}</Typography>
                                                        </Box>
                                                        {!isPostingStudentTechnique && (
                                                            <CircleIcon
                                                                fill={handleIndicatorFill(technique)}
                                                                onClick={props.editable ? (event) => event.stopPropagation() : undefined}
                                                                onMenuItemClick={props.editable ? handleStatusAction(technique) : undefined}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <SubCard elevation={0}>
                                                {((user?.role === Role.Student && matchedStudentTechnique?.coachNotes) || user?.role === Role.Coach) && (
                                                    <ListItem key={`${technique.techniqueId}-coach-notes`}>
                                                        <ListItemText sx={{ margin: "0px" }}
                                                            smalltext={props.ordered ? true : false}
                                                            primary={<Box display="flex">
                                                                Coach Notes
                                                                {user?.role === Role.Coach &&
                                                                    <Edit style={{ marginLeft: "5px" }} onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setDialogComponent(renderEditNoteDialog((matchedStudentTechnique || technique), handleNoteDialogClose, selectedStudent?.user_id || selectedStudent?.sub, "coach"))
                                                                    }} />
                                                                }
                                                            </Box>}
                                                            secondary={
                                                                matchedStudentTechnique?.coachNotes ?
                                                                    <ParagraphSpacer string={matchedStudentTechnique?.coachNotes} keyPrefix='coachNote' />
                                                                    : "--"
                                                            }
                                                        />
                                                    </ListItem>
                                                )}

                                                {((user?.role === Role.Coach && matchedStudentTechnique?.studentNotes) || user?.role === Role.Student) && (
                                                    <ListItem key={`${technique.techniqueId}-student-notes`}>
                                                        <ListItemText sx={{ margin: "0px" }}
                                                            smalltext={props.ordered ? true : false}
                                                            primary={<Box display="flex">
                                                                {user?.role === Role.Student ? "My Notes" : "Student Notes"}
                                                                {user?.role === Role.Student &&
                                                                    <Edit style={{ marginLeft: "5px" }} onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setDialogComponent(renderEditNoteDialog((matchedStudentTechnique || technique), handleNoteDialogClose, selectedStudent?.user_id || selectedStudent?.sub, "student"))
                                                                    }} />
                                                                }
                                                            </Box>
                                                            }
                                                            secondary={
                                                                matchedStudentTechnique?.studentNotes ?
                                                                    <ParagraphSpacer string={matchedStudentTechnique?.studentNotes} keyPrefix='studentNote' />
                                                                    : "--"
                                                            }
                                                        />
                                                    </ListItem>
                                                )}

                                                {(technique?.globalNotes || user?.role === Role.Coach) &&
                                                    <ListItem key={`${technique.techniqueId}-global-notes`}>
                                                        <ListItemText
                                                            smalltext={props.ordered ? true : false}
                                                            primary={<Box display="flex">
                                                                Global Notes
                                                                {user?.role === Role.Coach &&
                                                                    <Edit style={{ marginLeft: "5px" }} onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setDialogComponent(renderEditNoteDialog(technique, handleNoteDialogClose, selectedStudent?.user_id || selectedStudent?.sub))
                                                                    }} />}
                                                            </Box>
                                                            }
                                                            secondary={
                                                                technique?.globalNotes ?
                                                                    <ParagraphSpacer string={technique?.globalNotes} keyPrefix='globalNote' />
                                                                    : "--"
                                                            } />
                                                    </ListItem>
                                                }

                                                {technique?.videos && technique.videos.map((video, index) => (
                                                    <ListItem key={`${technique.techniqueId}-video-${index}`}>
                                                        <ListItemText
                                                            smalltext={props.ordered ? true : false}
                                                            primary={"Video: " + video.title}
                                                            secondary={
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => setVideoModalComponent(renderVideoModal(video.hyperlink, handleVideoModalClose))}
                                                                    sx={{ minWidth: "85px", marginLeft: "5px", marginTop: "5px" }}>
                                                                    <VideocamSharp />
                                                                </Button>}
                                                        />
                                                    </ListItem>
                                                ))}

                                                <ListItem key={`${technique.techniqueId}-description`}>
                                                    <ListItemText sx={{ margin: "0px" }}
                                                        smalltext={props.ordered ? true : false}
                                                        primary="Description"
                                                        secondary={technique?.description}
                                                    />
                                                </ListItem>


                                                <SubAccordion elevation={0} disableGutters square>
                                                    <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: "0px", margin: "0px" }}>
                                                        <ListItem key={`${technique.techniqueId}-position`}>
                                                            <ListItemText
                                                                smalltext={props.ordered ? true : false}
                                                                primary="Position"
                                                                secondary={technique?.position?.title}
                                                            />
                                                        </ListItem>
                                                    </AccordionSummary>

                                                    <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>
                                                        <ListItem key={`${technique.techniqueId}-position-description`}>
                                                            <ListItemText
                                                                smalltext={props.ordered ? true : false}
                                                                secondary={technique?.position.description} />
                                                        </ListItem>
                                                    </AccordionDetails>
                                                </SubAccordion>

                                                <ListItem key={`${technique.techniqueId}-hierarchy`}>
                                                    <ListItemText
                                                        smalltext={props.ordered ? true : false}
                                                        primary="Hierarchy"
                                                        secondary={technique?.hierarchy}
                                                    />
                                                </ListItem>

                                                <SubAccordion elevation={0} disableGutters square>
                                                    <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: "0px", margin: "0px" }}>
                                                        <ListItem key={`${technique.techniqueId}-type`}>
                                                            <ListItemText
                                                                smalltext={props.ordered ? true : false}
                                                                primary="Type"
                                                                secondary={technique?.type.title}
                                                            />
                                                        </ListItem>
                                                    </AccordionSummary>

                                                    <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>
                                                        <ListItem key={`${technique.techniqueId}-type-description`}>
                                                            <ListItemText
                                                                smalltext={props.ordered ? true : false}
                                                                secondary={technique?.type.description} />
                                                        </ListItem>
                                                    </AccordionDetails>
                                                </SubAccordion>

                                                {technique?.openGuard && (
                                                    <SubAccordion elevation={0} disableGutters square>
                                                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: "0px", margin: "0px" }}>
                                                            <ListItem key={`${technique.techniqueId}-open-guard`}>
                                                                <ListItemText
                                                                    smalltext={props.ordered ? true : false}
                                                                    primary="Open Guard"
                                                                    secondary={technique?.openGuard?.title}
                                                                />
                                                            </ListItem>
                                                        </AccordionSummary>

                                                        <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>
                                                            <ListItem key={`${technique.techniqueId}-open-guard-description`}>
                                                                <ListItemText
                                                                    smalltext={props.ordered ? true : false}
                                                                    secondary={technique?.openGuard?.description} />
                                                            </ListItem>
                                                        </AccordionDetails>
                                                    </SubAccordion>
                                                )}

                                                <ListItem key={`${technique.techniqueId}-gi`}>
                                                    <ListItemText
                                                        smalltext={props.ordered ? true : false}
                                                        primary="Gi or No Gi"
                                                        secondary={technique?.gi}
                                                    />
                                                </ListItem>
                                            </SubCard>
                                        </AccordionDetails>
                                    </Accordion >
                                )
                            }) : <SubCard elevation={props.elevation} >
                                <Accordion >
                                    <AccordionSummary>
                                        <Box display="flex" flexDirection="row" width="100%">
                                            <Typography>{`No techniques available`}</Typography>
                                        </Box>
                                    </AccordionSummary>
                                </Accordion>
                            </SubCard>
                        : isError &&
                        <CardContent>
                            <Typography>{`Failed to fetch techniques: ${error}`}</Typography>
                        </CardContent>
            }
            {dialogComponent}
            {videoModalComponent}
        </>
    )
}

export default StudentTechniqueList
