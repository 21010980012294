import React from 'react'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';


interface VideoModalProps {
    src: string;
    onClose: () => void;
}

export const renderVideoModal = (src: string, onClose: () => void) => {
    return <VideoModal src={src} onClose={onClose}/>
}

function youtubeParser(url: string) {
    const regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
    const match = url.match(regExp)
    return match ? match[1] : ""
}

function googleDriveParser(url: string) {
    const match = url.match(/\/d\/(.+?)\//);
    return match ? `https://drive.google.com/file/d/${match[1]}/preview` : "";
}

const VideoModal = ({ src, onClose }: VideoModalProps) => {
    const [isOpen, setIsOpen] = React.useState(true)
    const handleClose = () => {
        setIsOpen(!isOpen)
        onClose()
    }

    let videoId = "";
    let channel: "youtube" | "custom" = "custom";

    if (src.includes("youtube") || src.includes("youtu.be")) {
        videoId = youtubeParser(src);
        channel = "youtube";
        console.log(videoId)
    } else if (src.includes("drive.google.com")) {
        videoId = googleDriveParser(src);
        console.log(videoId)

        channel = "custom";
    }

    return (
            <ModalVideo
                channel={channel}
                youtube={{ mute: 0, autoplay: 0 }}
                isOpen={isOpen}
                videoId={videoId}
                onClose={handleClose}
                url={channel === "custom" ? videoId : ""}
            />
    )
}
