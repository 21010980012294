import { User as Auth0User } from '@auth0/auth0-react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Collection, CollectionSet, CollectionTechnique, Descriptions, StudentTechnique, Suggestions, Technique } from 'common';
import { sec } from '../store/security';
import { stripAuth0FromUserId } from '../util/Utilities';
import { UserCreate, UserUpdate } from 'auth0';
import "immer";


interface User extends Auth0User {
    user_id: string | undefined;
}

type UpdateCollection = Partial<Pick<Collection, Exclude<keyof Collection, 'collectionTechniques'>>> & {
    collectionTechniques?: Partial<CollectionTechnique>[];
};

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}`
if (!baseUrl) throw new Error(`Error with baseUrl - is env variable set?`)

export const syllabusTrackerApi = createApi({
    reducerPath: 'syllabbusTrackerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: async (headers, { getState }) => {
            const token = await sec.getAccessTokenSilently()();
            if (token) { headers.set('Authorization', `Bearer ${token}`) }
            headers.set('Content-Type', 'application/json')
            return headers
        }
    }),
    tagTypes: [
        'Techniques',
        'Types',
        'Positions',
        'OpenGuards',
        'Collections',
        'CollectionTechniques',
        'Students',
        'StudentTechniques',
        'CollectionSets',
        'Descriptions',
        'TechniqueSuggestions',
        'CollectionSuggestions',
        'User'
    ],
    endpoints: (build) => ({
        // Technique queries and mutations
        getTechniques: build.query<Technique[], void>({
            query: () => `technique`,
            providesTags: (result) =>
                result ?
                    [
                        ...result.map(({ techniqueId }) => ({ type: 'Techniques' as const, techniqueId })),
                        { type: 'Techniques', id: 'LIST' }
                    ]
                    : [{ type: 'Techniques', id: 'LIST' }]
        }),
        postNewTechnique: build.mutation<Technique, Partial<Technique>>({
            query: technique => ({
                url: `technique`,
                method: 'POST',
                body: technique
            }),
            invalidatesTags: ['Descriptions', 'TechniqueSuggestions', 'CollectionSuggestions'],
            async onQueryStarted({ techniqueId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newTechnique } = await queryFulfilled
                    dispatch(syllabusTrackerApi.util.updateQueryData('getTechniques', undefined, (draft) => {
                        draft.unshift(newTechnique);
                    }));
                } catch { }
            }
        }),
        editTechnique: build.mutation<Technique, Partial<Technique>>({
            query: technique => ({
                url: `technique/${technique.techniqueId}`,
                method: 'PUT',
                body: technique
            }),
            invalidatesTags: [
                { type: 'Types', id: 'LIST' },
                { type: 'Positions', id: 'LIST' },
                { type: 'OpenGuards', id: 'LIST' },
                'Descriptions',
                'TechniqueSuggestions',
                'CollectionSuggestions'
            ],
            async onQueryStarted({ techniqueId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedTechnique } = await queryFulfilled
                    dispatch(syllabusTrackerApi.util.updateQueryData('getTechniques', undefined, draft => {
                        const index = draft.findIndex(t => t.techniqueId === techniqueId);
                        if (index !== -1) {
                            draft[index] = { ...draft[index], ...updatedTechnique };
                        }
                    })
                    );
                } catch { }
            },
        }),
        deleteTechnique: build.mutation<void, string>({
            query: techniqueId => ({
                url: `technique/${techniqueId}`,
                method: 'DELETE',
            }),
            async onQueryStarted(techniqueId, { dispatch, queryFulfilled }) {
                const techniquePatchResult = dispatch(
                    syllabusTrackerApi.util.updateQueryData('getTechniques', undefined, draft => {
                        const index = draft.findIndex(t => t.techniqueId === techniqueId);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    })
                );
                const allStudentTechniquesPatchResult = dispatch(
                    syllabusTrackerApi.util.updateQueryData('getAllStudentTechniques', undefined, draft => {
                        return draft.filter(st => st.technique.techniqueId !== techniqueId)
                    })
                );
                const selectedStudentTechniquesPatchResult = dispatch(
                    syllabusTrackerApi.util.updateQueryData('getSelectedStudentTechniques', techniqueId, draft => {
                        return draft.filter(st => st.technique.techniqueId !== techniqueId)
                    })
                );
                try {
                    await queryFulfilled
                } catch {
                    techniquePatchResult.undo()
                    allStudentTechniquesPatchResult.undo()
                    selectedStudentTechniquesPatchResult.undo()
                }
            },
        }),

        // Collection queries and mutations
        getCollections: build.query<Collection[], void>({
            query: () => `collection`,
            providesTags: (result) =>
                result ?
                    [
                        ...result.map(({ collectionId }) => ({ type: 'Collections' as const, collectionId })),
                        { type: 'Collections', id: 'LIST' }
                    ]
                    : [{ type: 'Collections', id: 'LIST' }]
        }),
        postNewCollection: build.mutation<Collection, Partial<Collection>>({
            query: collection => ({
                url: `collection`,
                method: 'POST',
                body: collection
            }),
            invalidatesTags: [
                'Descriptions',
                'CollectionSuggestions',
                'TechniqueSuggestions'
            ],
            async onQueryStarted({ collectionId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newCollection } = await queryFulfilled
                    dispatch(syllabusTrackerApi.util.updateQueryData('getCollections', undefined, (draft) => {
                        draft.unshift(newCollection);
                    }));
                } catch { }
            }
        }),
        editCollection: build.mutation<Collection, UpdateCollection>({
            query: collection => ({
                url: `collection/${collection.collectionId}`,
                method: 'PUT',
                body: collection
            }),
            invalidatesTags: [
                { type: 'Types', id: 'LIST' },
                { type: 'Positions', id: 'LIST' },
                { type: 'OpenGuards', id: 'LIST' },
                'Descriptions',
                'CollectionSuggestions',
                'TechniqueSuggestions'
            ],
            async onQueryStarted({ collectionId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newCollection } = await queryFulfilled
                    dispatch(syllabusTrackerApi.util.updateQueryData('getCollections', undefined, (draft) => {
                        const index = draft.findIndex(col => col.collectionId === newCollection.collectionId)
                        draft[index] = newCollection
                    }));
                } catch { }
            }
        }),
        deleteCollection: build.mutation<void, string>({
            query: collectionId => ({
                url: `collection/${collectionId}`,
                method: 'DELETE',
            }),
            async onQueryStarted(collectionId, { dispatch, queryFulfilled }) {
                const collectionsPatchResult = dispatch(
                    syllabusTrackerApi.util.updateQueryData('getCollections', undefined, draft => {
                        const index = draft.findIndex(c => c.collectionId === collectionId);
                        if (index !== -1) {
                            draft.splice(index, 1);
                        }
                    })
                );
                try {
                    await queryFulfilled
                } catch {
                    collectionsPatchResult.undo()
                }
            },
        }),

        // Students queries and mutations
        getStudents: build.query<User[], void>({
            query: () => `student`,
            transformResponse: (response: User[], meta, arg) => {
                return response.map(user => {
                    return {
                        ...user,
                        sub: user.user_id?.replace("auth0|", "") || user.sub?.replace("auth0|", ""),
                        user_id: user.user_id?.replace("auth0|", "") || user.sub?.replace("auth0|", ""),
                        role: user.app_metadata?.roles[0]
                    }
                })
            },
            providesTags: (result) =>
                result ?
                    [
                        ...result.map(({ sub }) => ({ type: 'Students' as const, sub })),
                        { type: 'Students', id: 'LIST' }
                    ]
                    : [{ type: 'Students', id: 'LIST' }]
        }),
        getSelectedStudentTechniques: build.query<StudentTechnique[], string>({
            query: studentId => `studenttechnique/${studentId}`,
            providesTags: (result) =>
                result ?
                    [
                        ...result.map(({ studentTechniqueId }) => ({ type: 'StudentTechniques' as const, studentTechniqueId })),
                        { type: 'StudentTechniques', id: 'LIST' }
                    ]
                    : [{ type: 'StudentTechniques', id: 'LIST' }]
        }),
        postStudentTechniques: build.mutation<StudentTechnique[], Partial<StudentTechnique>[]>({
            query: data => ({
                url: `studenttechnique/${data[0].userId}`,
                method: `POST`,
                body: data
            }),
            invalidatesTags: [
                { type: 'StudentTechniques', id: 'LIST' },
            ]
        }),
        postStudentTechnique: build.mutation<StudentTechnique, Partial<StudentTechnique>>({
            query: data => ({
                url: `studenttechnique/${data.userId}/${data.technique?.techniqueId}`,
                method: `POST`,
                body: data
            }),
            invalidatesTags: [
                { type: 'StudentTechniques', id: 'LIST' },
            ]
        }),
        editStudentTechnique: build.mutation<StudentTechnique, Partial<StudentTechnique>>({
            query: data => ({
                url: `studenttechnique/${data.studentTechniqueId}/${data.technique?.techniqueId}`,
                method: `PUT`,
                body: data
            }),
            invalidatesTags: [
                { type: 'StudentTechniques', id: 'LIST' },
            ],
        }),
        getAllStudentTechniques: build.query<StudentTechnique[], void>({
            query: () => `student/technique`,
            providesTags: (result) =>
                result ?
                    [
                        ...result.map(({ studentTechniqueId }) => ({ type: 'StudentTechniques' as const, studentTechniqueId })),
                        { type: 'StudentTechniques', id: 'LIST' }
                    ]
                    : [{ type: 'StudentTechniques', id: 'LIST' }]
        }),
        deleteStudentTechnique: build.mutation<void, string>({
            query: studentTechniqueId => ({
                url: `student/technique/${studentTechniqueId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'StudentTechniques', id: 'LIST' },
            ]
        }),

        // Collection sets queries and mutations
        getCollectionSets: build.query<CollectionSet[], void>({
            query: () => `collectionset`,
            providesTags: (result) =>
                result ?
                    [
                        ...result.map(({ collectionSetId }) => ({ type: 'CollectionSets' as const, collectionSetId })),
                        { type: 'CollectionSets', id: 'LIST' }
                    ]
                    : [{ type: 'CollectionSets', id: 'LIST' }]
        }),
        postCollectionSet: build.mutation<CollectionSet, Partial<CollectionSet>>({
            query: collectionSet => ({
                url: `collectionset`,
                method: `POST`,
                body: collectionSet
            }),
            invalidatesTags: [
                { type: 'CollectionSets', id: 'LIST' },
            ]
        }),
        editCollectionSet: build.mutation<CollectionSet, Partial<CollectionSet>>({
            query: collectionSet => ({
                url: `collectionset/${collectionSet.collectionSetId}`,
                method: `PUT`,
                body: collectionSet
            }),
            invalidatesTags: [
                { type: 'CollectionSets', id: 'LIST' },
            ]
        }),
        deleteCollectionSet: build.mutation<void, string>({
            query: collectionSetId => ({
                url: `collectionset/${collectionSetId}`,
                method: `DELETE`
            }),
            invalidatesTags: [
                { type: 'CollectionSets', id: 'LIST' },
            ]
        }),

        // Descriptions query
        getDescriptions: build.query<Descriptions, void>({
            query: () => `technique/descriptions`,
            providesTags: ['Descriptions']
        }),

        // Technique suggestions query
        getTechniqueSuggestions: build.query<Suggestions, void>({
            query: () => `technique/suggestions`,
            providesTags: ['TechniqueSuggestions']
        }),

        // Collection suggestions query
        getCollectionSuggestions: build.query<Suggestions, void>({
            query: () => `collection/suggestions`,
            providesTags: ['CollectionSuggestions']
        }),

        // Fetch user query
        getUser: build.query<User, void>({
            query: () => `user`,
            providesTags: ['User'],
            transformResponse: (response: User) => {
                return response.user_id ? {
                    ...response,
                    user_id: stripAuth0FromUserId(response?.user_id),
                    role: response?.app_metadata?.roles[0]
                } : { ...response }
            },
        }),
        updateUser: build.mutation<User, UserUpdate>({
            query: user => ({
                url: `user`,
                method: `PUT`,
                body: user
            }),
            async onQueryStarted(user, { dispatch, queryFulfilled }) {
                const userPatchResult = dispatch(
                    syllabusTrackerApi.util.updateQueryData('getUser', undefined, draft => {
                        Object.assign(draft, user)
                    })
                );
                try {
                    await queryFulfilled
                } catch {
                    userPatchResult.undo()
                }
            },
        }),
        createUser: build.mutation<User, UserCreate>({
            query: user => ({
                url: `user`,
                method: `POST`,
                body: user
            }),
            invalidatesTags: ['Students']
        })
    }),
})

export default syllabusTrackerApi;
