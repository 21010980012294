import React from 'react';

interface ParagraphSpacerProps {
    string: string,
    keyPrefix: string,
}

export function ParagraphSpacer({ string, keyPrefix }: ParagraphSpacerProps) {
    return (
        <>
            {string.split('\n').map((line, index) => (
                <React.Fragment key={`para-spacer-${keyPrefix + "-" + index}`}>
                    {line}
                    <br />
                </React.Fragment>))}
        </>
    )
}
