import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'
import { Technique } from 'common';
import React from 'react'
import { renderEditTechniqueDialog } from '../../../components/Dialogs/EditTechniqueDialog'
import { renderNewTechniqueDialog } from '../../../components/Dialogs/NewTechniqueDialog'
import { TechniqueListWithFilters } from '../../../components/Lists/TechniquesListWithFilters'


function CoachTechniques(): JSX.Element {
    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)

    const [showNewTechniqueFab, setShowNewTechniqueFab] = React.useState(true)

    const handleTechniqueEditClick = (technique: Technique) => {
        setDialogComponent(renderEditTechniqueDialog(technique, handleDialogClose))
        setShowNewTechniqueFab(false)
    }

    const handleNewTechniqueClick = () => {
        setDialogComponent(renderNewTechniqueDialog(handleDialogClose))
        setShowNewTechniqueFab(false)
    }

    const handleDialogClose = () => {
        setDialogComponent(null)
        setShowNewTechniqueFab(true)
    }

    return (
        <div>
            <TechniqueListWithFilters
                onTechniqueEditClick={handleTechniqueEditClick}
                editable
            />

            <Fab
                color="primary"
                aria-label="add"
                style={{ position: 'fixed', bottom: '16px', right: '16px' }}
                onClick={handleNewTechniqueClick}
                hidden={!showNewTechniqueFab}
            >
                <AddIcon />
            </Fab>

            {dialogComponent}
        </div>
    );
};

export default CoachTechniques
