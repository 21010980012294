import { Role } from 'common';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BaseLayout from '../../components/Base/BaseLayout';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import CoachCollectionSets from '../users/coach/CollectionSets';


const CollectionSetsPage: React.FC = () => {
    const navigate = useNavigate();
    const { data: user, isSuccess } = syllabusTrackerApi.useGetUserQuery()

    if (isSuccess && user.role === Role.Student) {
        navigate('/')
    }

    return (
        <BaseLayout text="Collection Sets">
            {isSuccess && (
                user.role === Role.Coach ? <CoachCollectionSets />
                    : user.role === Role.Admin && <p>Admin Collections Sets page placeholder</p>
            )}

        </BaseLayout>
    );
}

export default CollectionSetsPage;
