import { CardContent, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import { Options, debounce } from 'ts-debounce';
import syllabusTrackerApi from 'src/services/syllabusTrackerApi';
import { FastTextField } from '../Fields/FastTextField';


const Card = styled(MuiCard)({
    backgroundColor: `#3c3836`,
    '&.MuiCard-root': {
        borderRadius: "2",
        boxShadow: "3"
    }
});

const Button = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", margin: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));


export const renderCreateUserDialog = (onClose: () => void) => {
    return <CreateUserDialog onClose={onClose} />
}

interface CreateUserDialogProps {
    onClose: () => void;
}

const CreateUserDialog = ({ onClose }: CreateUserDialogProps) => {
    const [wasSubmitted, setWasSubmitted] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(true)
    const [createUser] = syllabusTrackerApi.useCreateUserMutation();
    const [emailState, setEmailState] = React.useState('')
    const [confirmEmailState, setConfirmEmailState] = React.useState('')
    const validateEmailsMatch = () => emailState === confirmEmailState
    const formRef = React.useRef<HTMLFormElement>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setWasSubmitted(true)
        const formIsValid = formRef.current?.checkValidity() ?? false;
        const emailsMatch = validateEmailsMatch();
        if (formIsValid && emailsMatch) {
            try {
                const formData = new FormData(event.currentTarget)
                const fieldValues = Object.fromEntries(Array.from(formData.entries()).filter(([key]) => key !== 'confirmEmail'))
                createUser({
                    connection: "Username-Password-Authentication",
                    ...fieldValues
                })
                setWasSubmitted(false)
                handleClose()
            } catch (error) { throw new Error(`Error updating user profile: ${error}`) }
        } else {
            console.log("Form is invalid");
        }
    };

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedSubmit = debounce(handleSubmit, 5000, debounceOptions)

    const handleClose = () => {
        onClose()
        setIsOpen(false)
        setWasSubmitted(false)
    }

    return (
        <Dialog open={isOpen} onClose={() => { handleClose() }} scroll="paper" maxWidth="md" fullWidth>
            <Card>
                <CardContent>
                    <form ref={formRef} onSubmit={debouncedSubmit} id="userEditForm">
                        <Typography variant='h6' >New Student</Typography>
                        <Box>
                            <FastTextField
                                fullWidth
                                required
                                type="email"
                                label="Email"
                                sx={{ marginTop: 1 }}
                                margin="dense"
                                size="small"
                                name="email"
                                onBlur={e => {setEmailState(e.target.value); setConfirmEmailState(confirmEmailState)}}
                                wasSubmitted={wasSubmitted}
                            />
                        </Box>
                        <Box>
                            <FastTextField
                                fullWidth
                                required
                                label="Confirm Email"
                                type="email"
                                sx={{ marginTop: 1 }}
                                margin="dense"
                                size="small"
                                name="confirmEmail"
                                onBlur={e => {setConfirmEmailState(e.target.value); setEmailState(emailState)}}
                                helperText={validateEmailsMatch() ? undefined : "Confirm emails match"}
                                error={!(confirmEmailState === emailState)}
                                wasSubmitted={wasSubmitted}
                            />
                        </Box>
                        <Box>
                            <FastTextField
                                fullWidth
                                required
                                label="Display Name"
                                sx={{ marginTop: 1 }}
                                margin="dense"
                                size="small"
                                name="name"
                                wasSubmitted={wasSubmitted}
                            />
                        </Box>
                    </form>
                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            type="submit"
                            form="userEditForm"
                            fullWidth
                            sx={{ marginRight: 1 }}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            fullWidth
                            sx={{ marginLeft: 1 }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Dialog>
    )
};
