import { User } from "@auth0/auth0-react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";


interface StudentState {
    selectedStudent: User | null;
    shownFirstLogin: boolean;
    loading: boolean;
    error: string | null;
}

const initialState: StudentState = {
    selectedStudent: null,
    shownFirstLogin: false,
    loading: false,
    error: null,
};


const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        selectStudent: (state, action: PayloadAction<User>) => {
            state.selectedStudent = action.payload
        },
        showFirstLogin: (state) => {
            state.shownFirstLogin = true
        }
    },
});

export const { selectStudent, showFirstLogin } = studentSlice.actions;
export default studentSlice.reducer;
