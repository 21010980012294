import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Collection, CollectionSet } from 'common';
import React from 'react';
import { Options, debounce } from 'ts-debounce';
import CollectionList from '../Lists/Base Lists/CollectionList';
import CollectionFilter, { useHandleCollectionFilterChange } from '../Lists/List Filters/CollectionFilter';
import { renderNewCollectionDialog } from './NewCollectionDialog';


const Card = styled(MuiCard)({
    backgroundColor: `#3c3836`,
    '&.MuiCard-root': {
        margin: "10px",
        marginBottom: "0px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

const Button = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", marginX: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));

interface AddCollectionToCollectionSetDialogProps {
    onClose: () => void;
    onSave: (selectedCollections: { index: number, collection: Collection }[]) => void;
    collectionSet: CollectionSet;
};

export const renderAddCollectionToCollectionSetDialog = (
    collectionSet: CollectionSet,
    onClose: () => void,
    onSave: (selectedTechniques: { index: number, collection: Collection }[]) => void
) => {
    return <AddCollectionToCollectionSetDialog collectionSet={collectionSet} onSave={onSave} onClose={onClose} />
}

const AddCollectionToCollectionSetDialog = ({ onClose, onSave, collectionSet }: AddCollectionToCollectionSetDialogProps) => {
    const [isOpen, setIsOpen] = React.useState(true)
    const [dialogComponent, setDialogComponent] = React.useState<JSX.Element | null>(null)
    const [cleanedCollections, setCleanedCollections] = React.useState<Collection[]>([]);
    const { filteredCollections, handleCollectionFilterChange } = useHandleCollectionFilterChange()
    const [checkedArray, setCheckedArray] = React.useState<boolean[]>(
        filteredCollections.map(t => { return false })
    )

    React.useEffect(() => {
        setCleanedCollections(filteredCollections.filter(fc => !collectionSet.collections?.some(c => c.collectionId === fc.collectionId)));
    }, [filteredCollections, setCleanedCollections, collectionSet.collections])

    const handleCollectionCheck = (checkedCollection: Collection) => {
        const index = cleanedCollections.findIndex(collection =>
            collection.collectionId === checkedCollection.collectionId
        )
        setCheckedArray(prevChecked => {
            const newChecked = [...prevChecked]
            newChecked[index] = !newChecked[index]
            return newChecked
        });
    };

    const handleAddCollections = () => {
        let selectedCollections: { index: number, collection: Collection }[] = cleanedCollections
            .map((collection, index) => ({ index, collection, checked: checkedArray[index] }))
            .filter(item => item.checked);

        setCleanedCollections(prevCleanedCollections => {
            return prevCleanedCollections.filter(collection =>
                !selectedCollections.some(sc => sc.collection.collectionId === collection.collectionId)
            )
        })
        setCheckedArray([])
        onSave(selectedCollections)
        handleClose()
    }

    const handleNewCollectionClick = () => {
        setDialogComponent(renderNewCollectionDialog(handleDialogClose))
    }

    const handleDialogClose = () => {
        setDialogComponent(null)
    }

    const handleClose = () => {
        setDialogComponent(null)
        setIsOpen(false)
        onClose();
    }

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedAddCollections = debounce(handleAddCollections, 5000, debounceOptions)

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} scroll="paper" maxWidth="md" fullWidth>
                <DialogTitle sx={{ padding: "0px", marginBottom: "10px" }}>
                    <Card>
                        <CollectionFilter onCollectionFiltersChange={handleCollectionFilterChange} />
                    </Card>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                            <Button disabled={(checkedArray.length === 0)} onClick={(event) => { event.stopPropagation(); debouncedAddCollections(); handleClose(); }}>Add</Button>
                            <Button onClick={(event) => { event.stopPropagation(); handleClose() }}>Cancel</Button>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                            <Button onClick={handleNewCollectionClick}>Create New Collection</Button>
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent dividers={true} sx={{ padding: "0px" }}>
                    <Card>
                        <CollectionList
                            collections={cleanedCollections ?? filteredCollections}
                            checkbox
                            elevation={1}
                            checkedArray={checkedArray}
                            onCheck={handleCollectionCheck}
                        />
                    </Card>
                    <div style={{ paddingTop: "10px" }} />
                </DialogContent>
            </Dialog>
            {dialogComponent}
        </>
    )
};
