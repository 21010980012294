import { User } from '@auth0/auth0-react';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Card, IconButton } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';


const Accordion = styled(MuiAccordion)({
    backgroundColor: `#3c3836`,
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    },
    '&:not(:last-child)': {
        borderBottom: '1px solid #7c6f64'
    }
});

const ListItem = styled(MuiListItem)({
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px"
})

const BaseListItemText: React.FC<ListItemTextProps> = (props) => {
    return (
        <MuiListItemText
            {...props}
            secondaryTypographyProps={{ component: 'div' }}
            primaryTypographyProps={{ component: 'div' }}
        />
    );
}

const ListItemText = styled(BaseListItemText)<ListItemTextProps>(({ theme }) => {
    let primaryVariant = 'h6';
    let secondaryVariant = 'body1';

    return {
        '& .MuiTypography-root': {
            variant: primaryVariant
        },
        '& .MuiTypography-colorTextSecondary': {
            variant: secondaryVariant
        }
    };
});

const SubCard = styled(MuiCard)({
    backgroundColor: 'inherit'
})

interface StudentListProps {
    students: User[],
    onSelectStudent: (student: User) => void;
}

function StudentList({ students, onSelectStudent }: StudentListProps): JSX.Element {
    const handleSelectStudent = (student: User) => {
        onSelectStudent(student); 
    }

    return (
        <Card>
            {students.map(student =>
                <Accordion disableGutters key={student.user_id} >
                    <AccordionSummary aria-controls="panel1a-content">
                        <Box display="flex" flexDirection="column" flexGrow={1}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="97%">
                                <Typography variant="body1">{student.name}</Typography>
                                <IconButton color="inherit" onClick={e => {e.stopPropagation(); handleSelectStudent(student);} }><ArrowCircleRightIcon /></IconButton>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SubCard elevation={0}>
                            <ListItem key={`${student.studentId}-email`}>
                                <ListItemText primary="Email" secondary={student.email} />
                            </ListItem>
                        </SubCard>
                    </AccordionDetails>
                </Accordion>
            )}
        </Card>
    )
}


export default StudentList
