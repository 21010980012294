import { Role } from 'common';
import React from 'react';
import BaseLayout from '../../components/Base/BaseLayout';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import CoachDashboard from '../users/coach/Dashboard';
import StudentDashboard from '../users/student/Dashboard';


const DashboardPage: React.FC = () => {
    const { data: user, isSuccess } = syllabusTrackerApi.useGetUserQuery()
    return (
        <BaseLayout text="Dashboard">
            {isSuccess && (
                user.role === Role.Student ? <StudentDashboard />
                    : user.role === Role.Coach ? <CoachDashboard />
                        : user.role === Role.Admin && <p>Admin dashboard page placeholder</p>
            )}
        </BaseLayout>
    )
};

export default DashboardPage;
