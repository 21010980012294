import { CardContent, styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { Options, debounce } from 'ts-debounce';
import syllabusTrackerApi from '../../services/syllabusTrackerApi';
import { transformCollectionForPost } from '../../util/Utilities';
import Pageloader from '../Base/PageLoader';
import { FastTextField } from '../Fields/FastTextField';
import { SelectField } from '../Fields/SelectField';
import { TextFieldWithDescriptionField } from '../Fields/TextFieldWithDescriptionField';
import { TitleTextField } from '../Fields/TitleTextField';


const TextField = styled(FastTextField)({
    marginTop: "15px"
})

const Card = styled(MuiCard)({
    backgroundColor: `#3c3836`,
    '&.MuiCard-root': {
        margin: "10px",
        borderRadius: "2",
        boxShadow: "3"
    }
});

const Button = styled((props: ButtonProps) => (
    <MuiButton sx={{ width: "100%", margin: "10px" }} variant='contained' {...props} />
))(({ theme }) => ({}));

interface NewCollectionDialogProps {
    onClose: () => void;
}

export const renderNewCollectionDialog = (onClose: () => void) => {
    return <NewCollectionDialog onClose={onClose} />
}

const NewCollectionDialog = ({ onClose }: NewCollectionDialogProps) => {
    const { data: collectionSuggestions, isLoading: suggestionsLoading, isSuccess: suggestionsSuccess } = syllabusTrackerApi.useGetCollectionSuggestionsQuery()
    const { data: descriptions, isLoading: descriptionsLoading, isSuccess: descriptionsSuccess } = syllabusTrackerApi.useGetDescriptionsQuery()
    const [postCollection] = syllabusTrackerApi.usePostNewCollectionMutation();

    const [isOpen, setIsOpen] = React.useState(true)
    const [wasSubmitted, setWasSubmitted] = React.useState(false);
    const [localPositionState, setLocalPositionState] = React.useState('')

    const handlePositionBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const newPosition = event.target.value || '';
        setLocalPositionState(newPosition);
    };

    const isPositionOpenGuard = localPositionState.toLowerCase() === 'open guard';

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setWasSubmitted(true)
        if (event.currentTarget.checkValidity()) {
            const formData = new FormData(event.currentTarget)
            const fieldValues = Object.fromEntries(formData.entries())
            const validCollection = transformCollectionForPost(fieldValues);
            await postCollection(validCollection)
            setWasSubmitted(false)
            handleClose()
        } else {
            console.log("Form is invalid");
        }
    };

    const debounceOptions: Options<ReturnType<(...args: any) => any>> = {
        isImmediate: true,
        maxWait: undefined,
        callback: undefined
    }

    const debouncedSubmit = debounce(handleSubmit, 5000, debounceOptions)

    const handleClose = () => {
        setIsOpen(false)
        onClose()
        setWasSubmitted(false)
    }

    return (
        <>
            {(suggestionsLoading || descriptionsLoading) ? <CardContent><Pageloader /></CardContent>
                : (suggestionsSuccess && descriptionsSuccess) &&
                <Dialog open={isOpen} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
                    <DialogTitle sx={{ padding: "0px", marginBottom: "10px" }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={0}>
                            <Button type="submit" form="newCollectionForm" onClick={(event) => { event.stopPropagation(); }}>Save</Button>
                            <Button onClick={(event) => { event.stopPropagation(); handleClose() }}>Cancel</Button>
                        </Box>
                    </DialogTitle>
                    <DialogContent dividers={true} sx={{ padding: "0px", borderBottom: "none" }}>
                        <form id="newCollectionForm" onSubmit={debouncedSubmit}>
                            <Card>
                                <CardContent>
                                    <TitleTextField wasSubmitted={wasSubmitted} size="small" fullWidth required
                                        name="title" label="Collection Title" options={collectionSuggestions.title} />

                                    <TextField wasSubmitted={wasSubmitted} size="small" fullWidth required
                                        multiline rows={4} name="description" label="Collection Description" />

                                    <TextField wasSubmitted={wasSubmitted} size="small" fullWidth
                                        multiline rows={4} name="globalNotes" label="Global Notes" />

                                    <TextFieldWithDescriptionField wasSubmitted={wasSubmitted} size="small" fullWidth name="position"
                                        label="Position" descriptionLabel='Position Description' options={collectionSuggestions.position}
                                        onPositionBlur={handlePositionBlur} descriptions={descriptions} />

                                    <TextFieldWithDescriptionField wasSubmitted={wasSubmitted} size="small" fullWidth name="type"
                                        label="Type" descriptionLabel='Type Description' options={collectionSuggestions.type}
                                        descriptions={descriptions} />

                                    <TextFieldWithDescriptionField wasSubmitted={wasSubmitted} size="small" fullWidth name="openGuard"
                                        label="Open Guard" descriptionLabel="Open Guard Description" options={collectionSuggestions.openguard}
                                        descriptions={descriptions} hidden={!isPositionOpenGuard} disabled={!isPositionOpenGuard} required={isPositionOpenGuard} />

                                    <SelectField wasSubmitted={wasSubmitted} name="hierarchy" label="Hierarchy" options={collectionSuggestions.hierarchy} />

                                    <SelectField wasSubmitted={wasSubmitted} name="gi" label="Gi" options={collectionSuggestions.gi} />
                                </CardContent>
                            </Card>
                        </form>
                    </DialogContent>
                </Dialog >
            }
        </>
    );
};
