import { Box, Button, CardContent, ListItemText, Typography, styled } from "@mui/material";
import MuiCard from '@mui/material/Card';
import React from "react";
import { FastTextField } from "../../../components/Fields/FastTextField";
import syllabusTrackerApi from "../../../services/syllabusTrackerApi";


const Card = styled(MuiCard)({
    '&.MuiCard-root': {
        marginLeft: "10px",
        marginTop: "10px",
        marginRight: "10px",
        borderRadius: "2",
        boxShadow: "3",
        backgroundColor: `#3c3836`
    }
});

function ProfileComponent() {
    const { data: user } = syllabusTrackerApi.useGetUserQuery()
    const [updateUser] = syllabusTrackerApi.useUpdateUserMutation()

    const [wasSubmitted, setWasSubmitted] = React.useState(false)
    const [editing, setEditing] = React.useState(false)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setWasSubmitted(true)
        setEditing(false)
        if (event.currentTarget.checkValidity()) {
            try {
                const formData = new FormData(event.currentTarget)
                const fieldValues = Object.fromEntries(formData.entries())
                updateUser({
                    ...fieldValues
                })
            } catch (error) { throw new Error(`Error updating user profile: ${error}`) }
        } else {
            console.log("Form is invalid");
        }
    };

    return (
        <Card>
            <CardContent>
                <form onSubmit={handleSubmit} id="userEditForm">
                    <Box>
                        <Typography variant="subtitle1">Email Address</Typography>
                        <FastTextField
                            fullWidth
                            sx={{ marginTop: 1 }}
                            margin="dense"
                            size="small"
                            name="email"
                            defaultValue={user?.email}
                            wasSubmitted={wasSubmitted}
                            disabled={!editing}
                        />
                    </Box>
                    <Box>
                        <Typography variant="subtitle1">Display Name</Typography>
                        <FastTextField
                            fullWidth
                            sx={{ marginTop: 1 }}
                            margin="dense"
                            size="small"
                            name="name"
                            defaultValue={user?.name}
                            wasSubmitted={wasSubmitted}
                            disabled={!editing}
                        />
                    </Box>
                </form>

                <Box>
                    <ListItemText primary="App Role" secondary={user?.app_metadata.roles[0]} />
                </Box>
                <Box>
                    <ListItemText primary="Login Count" secondary={user?.logins_count} />
                </Box>

                <Box>
                    <ListItemText primary="Last Login" secondary={formatDate(user?.last_login)} />
                </Box>


                <Box>
                    <ListItemText primary="User Since" secondary={formatDate(user?.created_at)} />
                </Box>

                <Box display="flex" justifyContent="center">
                    {!editing &&
                        <Button
                            variant="contained"
                            type="submit"
                            form="userEditForm"
                            onClick={() => setEditing(true)}
                            fullWidth
                        >
                            Edit
                        </Button>
                    }
                    {editing &&
                        <>
                            <Button
                                variant="contained"
                                type="submit"
                                form="userEditForm"
                                fullWidth
                                sx={{ marginRight: 1 }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => setEditing(false)}
                                fullWidth
                                sx={{ marginLeft: 1 }}
                            >
                                Cancel
                            </Button>
                        </>
                    }
                </Box>
            </CardContent>
        </Card>
    )
}

function formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export default ProfileComponent
